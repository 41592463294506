import * as React from "react";
import { Row, Col, Tab, Tabs } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useRouteMatch, useLocation } from "react-router";
import { push } from "connected-react-router/immutable";
import RsfPresentationTab from "@rsfApp/app2/src/components/JobTabs/PresentationTab/Existing";
import { RootState } from "@rsfApp/app2/src/reducers";
import { currentPresentationId } from "@rsfApp/app2/src/selectors/presentation.selectors";
import { currentJobId } from "@rsfApp/app2/src/selectors/job.selectors";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import PresentationBuilderContainer from "app2/src/Components/PresentationBuilder/PresentationBuilderContainer";

export const PresentationTab: React.FC = () => {
  // Hooks
  const match = useRouteMatch();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const setInitialActiveKey = () => {
    let initialActiveKey = "upload";
    if (pathname.endsWith("build")) initialActiveKey = "build";

    return initialActiveKey;
  };
  // Local State
  const [activeKey, setActiveKey] = React.useState(setInitialActiveKey());

  // Selectors
  const presentationId = useSelector((state: RootState) => currentPresentationId(state));
  const jobId = useSelector(currentJobId);

  // Methods
  const tabNavigate = (data: string) => {
    setActiveKey(data);
    if (data === "build") {
      dispatch(push(`${match.url}/build`));
    } else {
      dispatch(push(`${match.url}`));
    }
  };

  React.useEffect(() => {
    if (presentationId) {
      const $state = StoreRegistry.get("$state");
      $state.go("presentationviewer", { id: jobId, presentation_id: presentationId });
    }
  }, [presentationId]);

  return (
    <Row>
      <Col className="gaf-nav-tabs">
        <Tabs onSelect={tabNavigate} aria-label="presentation-tabs" activeKey={activeKey}>
          <Tab eventKey="build" title="Build" />
          <Tab eventKey="upload" title="Upload" />
        </Tabs>

        {jobId && (
          <Switch>
            <Route path={`${match.url}/build`} component={PresentationBuilderContainer} />
            <Route path={`${match.url}`} component={RsfPresentationTab} />
          </Switch>
        )}
      </Col>
    </Row>
  );
};
