import { IStateService } from "angular-ui-router";
import { IOrg } from "@rsfApp/app/src/Models/Org";
import { IJob } from "@rsfApp/app/src/Models/Job";
import { JobMenuOrderService, IMenuItem } from "@rsfApp/app/src/JobMenu/JobMenuOrderService";
import { IJobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";
import { HandlebarsHelperService } from "@rsfApp/app/src/Patterns/HandlebarsHelperService";
import { dispatch } from "@rsfApp/app2/src/storeRegistry";
import { push } from "connected-react-router/immutable";

export interface IGafMenuItem extends IMenuItem {
  newJobVisible?: boolean;
  pageState?: string;
  pageUrl?: string;
  pageStates?: string[];
  iconImg?: string;
  iconImgReverse?: string;
}

export class GafJobMenuOrderService extends JobMenuOrderService {
  public static $inject = ["$state", "JobFetcher", "HandlebarsHelperService", "$location"];
  constructor(
    protected $state: IStateService,
    protected JobFetcher: IJobFetcherService,
    protected HandlebarsHelperService: HandlebarsHelperService,
    protected $location: ng.ILocationService
  ) {
    super($state, JobFetcher, HandlebarsHelperService);
  }

  public isCurrentPage(page: string): boolean {
    if (page === "/jobs") return false;
    return new RegExp(page).test(this.$location.path());
  }

  public imgUrl(menuItem: IGafMenuItem, mouse: boolean) {
    if (mouse || !this.isCurrentPage(menuItem.pageUrl)) {
      return menuItem.iconImgReverse;
    }

    return menuItem.iconImg;
  }

  // @ts-ignore
  public available(org: IOrg, job: IJob) {
    return [
      {
        id: "home",
        newJobVisible: true,
        analyticsEvent: "Home",
        pageUrl: "/jobs",
        pageState: "root.job_list",
        iconImg: "/assets/images/customers.d4a16c14.svg",
        iconImgReverse: "/assets/images/customers-reverse.58da87b2.svg",
        label: "Home",
        navigate: () => dispatch(push("/jobs")),
      },
      {
        id: "information",
        newJobVisible: true,
        analyticsEvent: "Information",
        pageUrl: "/info",
        pageState: "job_header.job_show.info",
        iconImg: "/assets/images/info.79412a5b.svg",
        iconImgReverse: "/assets/images/info-reverse.ac229951.svg",
        label: "Information",
        navigate: () => {
          if (job) {
            this.navigate("info", job);
          }
        },
      },
      {
        id: "property_data",
        newJobVisible: false,
        analyticsEvent: "Property Data",
        pageUrl: "/property",
        pageState: "job_header.job_show.info",
        iconImg: "/assets/images/info.79412a5b.svg",
        iconImgReverse: "/assets/images/info-reverse.ac229951.svg",
        label: "Property Data",
        navigate: () => this.navigate("property", job),
      },
      {
        id: "appointments",
        newJobVisible: false,
        analyticsEvent: "Appointments",
        pageUrl: "/appointments",
        pageState: "job_header.job_show.appointments",
        iconImg: "/assets/images/appointments.81e7e953.svg",
        iconImgReverse: "/assets/images/appointments-reverse.ce683456.svg",
        label: "Appointments",
        badge: () => job.appointments.length,
        navigate: () => this.navigate("appointments", job),
      },
      {
        id: "tools",
        newJobVisible: false,
        analyticsEvent: "Tools",
        pageUrl: "/tools",
        pageState: "job_header.job_show.tools",
        iconImg: "/assets/images/tools.d1e395ea.svg",
        iconImgReverse: "/assets/images/tools-reverse.203f58f1.svg",
        label: "Tools",
        navigate: () => this.navigate("tools", job),
      },
      {
        id: "inspection",
        newJobVisible: false,
        analyticsEvent: "Inspection",
        pageUrl: "/inspection",
        pageState: "job_header.job_show.inspection",
        iconImg: "/assets/images/inspection.a203036f.svg",
        iconImgReverse: "/assets/images/inspection-reverse.39b0103f.svg",
        label: "Inspection",
        navigate: () => {
          if (job) {
            this.navigate("inspection", job);
          }
        },
      },
      {
        id: "images",
        newJobVisible: false,
        analyticsEvent: "Photos",
        pageUrl: "/images",
        pageState: "job_header.job_show.images",
        iconImg: "/assets/images/photos.924286d4.svg",
        iconImgReverse: "/assets/images/photos-reverse.e1a1c13b.svg",
        label: "Photos",
        badge: () => job.image_count,
        navigate: () => this.navigate("images", job),
      },
      {
        id: "documents",
        newJobVisible: false,
        analyticsEvent: "Documents",
        pageUrl: "/documents",
        pageState: "job_header.job_show.documents",
        iconImg: "/assets/images/documents.c5a70b00.svg",
        iconImgReverse: "/assets/images/documents-reverse.cdfbd54d.svg",
        label: "Documents",
        badge: () => this.countDocsNotSigned(job),
        navigate: () => this.navigate("documents", job),
      },
      {
        id: "measurements",
        newJobVisible: false,
        analyticsEvent: "Measurements",
        pageUrl: "/measurements",
        pageState: "job_header.job_show.measurements",
        iconImg: "/assets/images/measurements.9062d54c.svg",
        iconImgReverse: "/assets/images/measurements-reverse.a388cb45.svg",
        label: "Measurements",
        navigate: () => this.navigate("measurements", job),
      },
      {
        id: "notes",
        newJobVisible: false,
        analyticsEvent: "Notes",
        pageUrl: "/notes",
        pageState: "job_header.job_show.notes",
        iconImg: "/assets/images/notes.722cc6ce.svg",
        iconImgReverse: "/assets/images/notes-reverse.f412b8d8.svg",
        label: "Notes",
        hideInPresentMode: true,
        badge: () => job.notes.length,
        navigate: () => this.navigate("notes", job),
      },
      {
        id: "history",
        newJobVisible: false,
        analyticsEvent: "History",
        pageUrl: "/history",
        pageState: "job_header.job_show.history",
        iconImg: "/assets/images/history.b897c6a3.svg",
        iconImgReverse: "/assets/images/history-reverse.2a5795f6.svg",
        label: "History",
        hideInPresentMode: true,
        navigate: () => this.navigate("history", job),
      },
      {
        id: "presentations",
        newJobVisible: false,
        analyticsEvent: "Presentations",
        pageUrl: "/presentations",
        pageState: "job_header.job_show.presentations",
        iconImg: "/assets/images/presentation.9b1be3fb.svg",
        iconImgReverse: "/assets/images/presentation-reverse.37d31cc6.svg",
        label: "Presentations",
        badge: () => this.countPresentations(org, job),
        navigate: () => this.navigate("presentations/build", job),
      },
      {
        id: "estimates",
        newJobVisible: false,
        analyticsEvent: "Estimates",
        pageUrl: "/estimates",
        pageStates: ["job_header.job_show.estimates", "job_header.job_show.estimate", "job_header.job_show.price"],
        iconImg: "/assets/images/estimate.83262a41.svg",
        iconImgReverse: "/assets/images/estimate-reverse.54684986.svg",
        label: org.estimatorTitle(false),
        badge: () => job.estimates.length,
        navigate: () => this.gotoEstimateTab("estimates", job),
      },
      {
        id: "proposals",
        newJobVisible: false,
        analyticsEvent: "Proposals",
        pageUrl: "/proposals",
        pageState: "job_header.job_show.proposals",
        iconImg: "/assets/images/proposals.270faea6.svg",
        iconImgReverse: "/assets/images/proposals-reverse.3310c98f.svg",
        label: org.estimateTitle(false),
        navigate: () => this.navigate("proposals", job),
      },
      {
        id: "contracts",
        newJobVisible: false,
        analyticsEvent: "Contracts",
        pageUrl: "/contracts",
        pageState: "job_header.job_show.contracts",
        iconImg: "/assets/images/agreements.ee42c0d0.svg",
        iconImgReverse: "/assets/images/agreements-reverse.002dc0bc.svg",
        label: org.contractTitle(false),
        navigate: () => this.navigate("contracts", job),
      },
      {
        id: "signatures",
        newJobVisible: false,
        analyticsEvent: "Signatures",
        pageUrl: "/signatures",
        pageState: "job_header.job_show.signatures",
        iconImg: "/assets/images/signatures.a6a64ff8.svg",
        iconImgReverse: "/assets/images/signatures-reverse.6c9113db.svg",
        label: "Signatures",
        navigate: () => this.navigate("signatures", job),
      },
      {
        id: "sales_process",
        newJobVisible: false,
        analyticsEvent: "Sales Process",
        pageUrl: "/sales-process",
        pageState: "job_header.job_show.sales_process",
        iconImg: "/assets/images/sales-process.e9d633e3.svg",
        iconImgReverse: "/assets/images/sales-process-reverse.f2e21aac.svg",
        label: "Sales Process",
        navigate: () => this.navigate("sales_process", job),
      },
    ];
  }
}
