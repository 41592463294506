import { JobMenuValues } from "app/src/JobMenu/JobMenuValues";
import { JobMenuManagerComponent } from "@rsfApp/app/src/JobMenu/JobMenuManagerComponent";
import { GafJobMenuOrderService } from "./GafJobMenuOrderService";

export class GafJobMenuRoutes {
  public static load(app: ng.IModule) {
    app.component("rsfJobMenuManager", new JobMenuManagerComponent());

    app.service("GafJobMenuOrderService", GafJobMenuOrderService);

    app.value("JobMenuValues", JobMenuValues);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {}
}
