import * as ng from "angular";
import { ISession } from "@rsfApp/app/src/Common/SessionService";
import { IUser } from "@rsfApp/app/src/Models/User";
import { IFlash } from "@rsfApp/app/src/Common/FlashService";
import { INavDisplayArgs } from "@rsfApp/app/src/Jobs/JobShowCtrl";
import { JobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";
import { IJob } from "@rsfApp/app/src/Models/Job";
import * as angulartics from "angulartics";
import { TransitionService } from "@uirouter/angularjs";
import { IBaseConfig } from "@rsfApp/app/src/Common/IBaseConfig";
import { IAdInfo } from "@rsfApp/app/src/Common/AdService";
import { JobShowTourService } from "@rsfApp/app/src/Common/Tours/JobShowTourService";
import { JobInfoTourService } from "@rsfApp/app/src/Common/Tours/JobInfoTourService";
import { subscriber } from "@rsfApp/app2/src/helpers/Subscribe";
import * as commonActions from "@rsfApp/app2/src/reducers/components/common.actions";
import { RootStoreType } from "@rsfApp/app2/src/store";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import * as jobService from "@rsfApp/app2/src/api/job.service";
import { currentAccessUid } from "@rsfApp/app2/src/selectors/auth.selectors";
import { capitalize } from "humanize-plus";

export interface IHeaderShowArgs {
  show: boolean;
}

export interface IHeaderSearchArgs {
  searchString?: string;
}

export class GafHeaderCtrl {
  public job: IJob;
  public user: IUser;
  public search = "";
  public navDisplay = true;
  public showSearchBar = false;
  public isNavCollapsed = true;
  public adUrl: string;
  public testimonial: any;
  public hidden = false;
  public store: RootStoreType;
  public navImgSrc = {
    home: "",
    calendar: "",
    setup: "",
    salesProcess: "",
    leads: "",
    scheduler: "",
  };
  public showLeads = false;

  public _cleanupListeners: Function[] = [];

  public static $inject = [
    "Session",
    "Flash",
    "JobFetcher",
    "$state",
    "$scope",
    "$rootScope",
    "$location",
    "$timeout",
    "$analytics",
    "$transitions",
    "BaseConfig",
    "AdService",
    "JobShowTourService",
    "JobInfoTourService",
  ];
  constructor(
    private Session: ISession,
    public Flash: IFlash,
    public JobFetcher: JobFetcherService,
    public $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    public $timeout: ng.ITimeoutService,
    private $analytics: angulartics.IAnalyticsService,
    public $transitions: TransitionService,
    public BaseConfig: IBaseConfig,
    public AdService: IAdInfo,
    public JobShowTourService: JobShowTourService,
    public JobInfoTourService: JobInfoTourService
  ) {
    this.setupImgSrc();
    this.user = Session.currentUser;
    this.isLeadsAvailable();

    this.store = StoreRegistry.getStore();
    this.hidden = this.$state.params["header"] === "false" ? true : false;

    this._cleanupListeners.push(
      $rootScope.$on("auth:login", (event: ng.IAngularEvent, user: IUser) => {
        this.user = user;
        this.isLeadsAvailable();
      })
    );

    this._cleanupListeners.push(
      $rootScope.$on("auth:logout", () => {
        this.user = null;
        this.isLeadsAvailable();
      })
    );

    this._cleanupListeners.push(
      $rootScope.$on("nav:display", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
        this.navDisplay = args.navDisplay;
      })
    );

    this._cleanupListeners.push(
      $rootScope.$on("header:search", (event: ng.IAngularEvent, args: IHeaderShowArgs) => {
        this.clearSearchText();
        this.showSearchBar = args.show;
      })
    );

    // noinspection TypeScriptValidateTypes
    this._cleanupListeners.push(
      $transitions.onStart({}, (transition) => {
        if (!_.isUndefined(transition) && this.Session.isFreemium()) {
          this.AdService.randomAd(transition.entering()[0]["name"], "job_header_url").then((resolve) => {
            this.adUrl = resolve;
          });
          this.AdService.randomTestimonial().then((resolve) => {
            this.testimonial = resolve;
          });
        }
        this.showSearchBar = false;
      })
    );

    if (!_.isUndefined(this.AdService) && !_.isUndefined(this.$state.current)) {
      this.AdService.randomAd(this.getCurrentStateName(), "job_header_url").then((resolve) => {
        this.adUrl = resolve;
      });

      this.AdService.randomTestimonial().then((resolve) => {
        this.testimonial = resolve;
      });
    }

    this._cleanupListeners.push(
      $rootScope.$on("header:search_update", (event: ng.IAngularEvent, args: IHeaderSearchArgs) => {
        this.search = args.searchString;
      })
    );

    $scope.$on("$destroy", () => {
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });

    if (this.isJobPage()) {
      if (this.$state.params["id"] && this.$state.params["id"] !== "new") {
        this._loadJob();
      }
    }

    this._cleanupListeners.push(
      $transitions.onFinish({}, () => {
        this.$timeout(() => this.setupImgSrc());
      })
    );
  }

  public isLeadsAvailable(): void {
    this.Session.can("leads_module", "org_modules", null).then((value) => {
      this.showLeads = value;
    });
  }

  public logout() {
    this.$state.go("root.logout");
  }

  public getCurrentStateName(): string {
    return this.$state.current.name.replace(/\./g, "_");
  }

  public isJobPage(): boolean {
    return new RegExp(/^\/jobs\/+/).test(this.$location.path());
  }

  public isCurrentPage(page: string): boolean {
    return this.$location.path() === page;
  }

  public isCurrentState(name: string): boolean {
    return this.$state.current.name === name;
  }

  public help(type) {
    switch (type) {
      case "estimator":
        this.$analytics.eventTrack("estimate tour requested", {
          category: "Job Header",
          navDisplay: !this.navDisplay,
        });
        this.$rootScope.$broadcast("header:help:estimator");
        break;
      case "job_info":
        this.$analytics.eventTrack("job tour requested", {
          category: "Job Header",
          navDisplay: !this.navDisplay,
        });
        this.JobInfoTourService.help().then(() => {
          this.$rootScope.$broadcast("header:help:job_info");
          this.JobShowTourService.help();
        });
        break;
    }
  }

  public searchChanged() {
    this.$rootScope.$broadcast("header:search_change", <IHeaderSearchArgs>{
      searchString: this.search,
    });
  }

  public clearSearchText() {
    this.search = "";
  }

  public goToUrl(link: string, params: any = {}) {
    this.$state.go(link, params);
  }

  public toggleNav() {
    this.navDisplay = !this.navDisplay;
    this.store.dispatch(commonActions.Actions.setNavDisplay(this.navDisplay));
    this.JobFetcher.setPresentationState(this.navDisplay);
    this.$rootScope.$broadcast("nav:display", <INavDisplayArgs>{
      navDisplay: this.navDisplay,
    });
  }

  public leadsLink() {
    let url = this.BaseConfig.LEADS_URL;
    const accessUid = currentAccessUid(StoreRegistry.getStore().getState());
    if (accessUid) {
      url = `${url}?authUser=${accessUid}`; //this.accessUid = `?authUser=${gafAuth.getAccessUid()}`;
    }

    return url;
  }
  private setupImgSrc() {
    this.navImgSrc.home = this.isCurrentPage("/jobs")
      ? "/assets/images/customers.d4a16c14.svg"
      : "/assets/images/customers-reverse.58da87b2.svg";
    this.navImgSrc.calendar = this.isCurrentPage("/calendar")
      ? "/assets/images/appointments.81e7e953.svg"
      : "/assets/images/appointments-reverse.ce683456.svg";
    this.navImgSrc.setup = this.isCurrentPage("/orgs")
      ? "/assets/images/setup.43632aee.svg"
      : "/assets/images/setup-reverse.1a018917.svg";
    this.navImgSrc.salesProcess = this.isCurrentPage("/salesprocess")
      ? "/assets/images/sales-process.e9d633e3.svg"
      : "/assets/images/sales-process-reverse.f2e21aac.svg";
    this.navImgSrc.leads = this.isCurrentPage("/leads")
      ? "/assets/images/leads.b944ff07.svg"
      : "/assets/images/leads-reverse.290e4946.svg";
    this.navImgSrc.scheduler = this.isCurrentPage("/scheduler")
      ? "/assets/images/scheduler.d1e80b95.svg"
      : "/assets/images/scheduler-reverse.fae79e90.svg";
  }

  private navTitle() {
    let title = "";

    switch (this.$state.current.name) {
      case "job_header.job_show.info":
        title = "Info";
        break;
      case "job_header.job_show.appointments":
        title = "Appointments";
        break;
      case "job_header.job_show.inspection":
        title = "Inspection";
        break;
      case "job_header.job_show.estimate":
      case "job_header.job_show.estimates":
      case "job_header.job_show.price":
        title = "Estimate";
        break;
      case "job_header.job_show.documents":
        title = "Documents";
        break;
      case "job_header.job_show.images":
        title = "Photos";
        break;
      case "job_header.job_show.tools":
        title = "Tools";
        break;
      case "job_header.job_show.measurements":
        title = "Measurements";
        break;
      case "job_header.job_show.contract":
      case "job_header.job_show.contracts":
        title = "Agreements";
        break;
      case "job_header.job_show.notes":
        title = "Notes";
        break;
      case "job_header.job_show.history":
        title = "History";
        break;
      case "job_header.job_show.sales_process":
        title = "Sales Process";
        break;
      case "job_header.job_show.react-job-tabs":
        const page = this.$state.params.path?.split("/")[0];
        title = capitalize(page);

        break;
    }
    return title;
  }

  private _loadJob() {
    this.job = this.JobFetcher.get(this.$state.params["id"]);
    this.job.$promise.then(() => {
      if (this.job.viewed === false) {
        jobService.view(this.job.org_id, this.job.id).then(() => {});
      }
    });
  }
}
