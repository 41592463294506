import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Row, Col } from "react-bootstrap";
import { UserRecord } from "@rsfApp/app2/src/records/UserRecord";
import { DateFormat } from "@rsfApp/app2/src/helpers/Format";

const mapStateToProps = (state: RootState, ownProps: INextAppointmentProps) => {
  return {
    currentUser: state.getIn(["users", "currentUser"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: INextAppointmentProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface INextAppointmentProps {
  response: any;
  $state: ng.ui.IStateService;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & INextAppointmentProps;

export interface INextAppointmentState {
  step: number;
}

class NextAppointment extends React.Component<Props, INextAppointmentState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 0,
    };
    this.nextStep = this.nextStep.bind(this);
    this.previousStep = this.previousStep.bind(this);
  }

  public nextStep() {
    this.setState((prevState) => {
      const { events } = this.props.response;
      let newStep = prevState.step + 1;
      if (newStep > events.length - 1) {
        newStep = events.length - 1;
      }
      return {
        step: newStep,
      };
    });
  }

  public previousStep() {
    this.setState((prevState) => {
      const { events } = this.props.response;
      let newStep = prevState.step - 1;
      if (newStep < 0) {
        newStep = 0;
      }
      return {
        step: newStep,
      };
    });
  }

  public render() {
    const { response, $state } = this.props;
    const { step } = this.state;
    let event_info = (
      <React.Fragment>
        <p>No Appointments</p>
        <p>Scheduled In Next</p>
        <p>2 Days</p>
      </React.Fragment>
    );
    if (!_.isUndefined(response) && !_.isUndefined(response.events) && response.events.length > 0) {
      const event = response.events[step];
      let phone = "";
      if (event.job.phone_numbers[0]) {
        phone = event.job.phone_numbers[0].number;
      }
      let arrows = null;
      const event_count = response.events.length;
      if (event_count > 1) {
        arrows = (
          <React.Fragment>
            {step === 0 ? null : (
              <img id="arrow-left" className="link" src="/assets/images/left-dark.f6dad295.svg" onClick={this.previousStep} />
            )}
            {step + 1 === event_count ? null : (
              <img id="arrow-right" className="link" src="/assets/images/right-dark.a88017b2.svg" onClick={this.nextStep} />
            )}
          </React.Fragment>
        );
      }

      event_info = (
        <React.Fragment>
          {arrows}
          <div className="event-container" onClick={() => $state.go("job_header.job_show.info", { id: event.job_id })}>
            <p>{DateFormat(event.start_time, "shortDate")}</p>
            <p>
              {DateFormat(event.start_time, "shortTime")} - {DateFormat(event.end_time, "shortTime")}
            </p>
            <p>{event.job.name}</p>
            <p>{phone}</p>
            <p>{event.job.address.fullAddress()}</p>
          </div>
        </React.Fragment>
      );
    }
    return (
      <Row>
        <Col sm={12}>
          <div className="form-section next-appointment">
            <h5>What's Next?</h5>
            <img
              className="link"
              id="calendar"
              src="/assets/images/appointment.4d212044.svg"
              onClick={() => $state.go("root.calendar")}
            />
            {event_info}
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(NextAppointment);
