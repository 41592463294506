import { GafProfileCtrl } from "app/src/Profile/GafProfileCtrl";
import { AcceptTosCtrl } from "@rsfApp/app/src/Profile/AcceptTosCtrl";
import { AlertCtrl } from "@rsfApp/app/src/Profile/AlertCtrl";
import { ErrorCtrl } from "@rsfApp/app/src/Profile/ErrorCtrl";
import TaskList from "@rsfApp/app2/src/components/Task/List";
import { react2angular } from "react2angular";
import Notifications from "@rsfApp/app2/src/components/Profile/Notifications";
import PermissionsErrorPage from "@rsfApp/app2/src/components/ErrorPages/Permissions";
import ClearWorkersButton from "@rsfApp/app2/src/components/Common/ClearWorkersButton";

export class GafProfileRoutes {
  public static load(app: ng.IModule) {
    app.controller("GafProfileCtrl", GafProfileCtrl);
    app.controller("AcceptTosCtrl", AcceptTosCtrl);
    app.controller("AlertCtrl", AlertCtrl);
    app.controller("ErrorCtrl", ErrorCtrl);
    app.component("clearWorkersButton", react2angular(ClearWorkersButton));

    app.component("taskList", react2angular(TaskList));
    app.component(
      "notifications",
      react2angular(Notifications, ["user", "userPreferences", "updateUserNotifications", "setNotificationError"])
    );
    app.component("permissionsErrorPage", react2angular(PermissionsErrorPage));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.profile", <ng.ui.IState>{
        url: "/profile?authUser=",
        views: {
          "container@": {
            templateUrl: "gaf/src/Profile/profile.html",
            controller: "GafProfileCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.tos", <ng.ui.IState>{
        url: "/tos",
        views: {
          "container@": {
            templateUrl: "src/Profile/accept_tos.html",
            controller: "AcceptTosCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.error", <ng.ui.IState>{
        url: "/404",
        views: {
          "container@": {
            templateUrl: "src/Profile/error.html",
            controller: "ErrorCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("root.permissions", <ng.ui.IState>{
        url: "/error",
        views: {
          "container@": {
            component: "permissionsErrorPage",
          },
        },
      })
      .state("root.alert", <ng.ui.IState>{
        url: "/alert",
        views: {
          "container@": {
            templateUrl: "src/Profile/alert.html",
            controller: "AlertCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("root.tasks", <ng.ui.IState>{
        url: "/tasks",
        views: {
          "container@": {
            component: "taskList",
          },
        },
        resolve: resolveAuth,
      });
  }
}
