import * as React from "react";
import { RootState } from "@rsfApp/app2/src/reducers";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { history } from "@rsfApp/app2/src/store";
import track from "react-tracking";
import { Dispatch } from "@rsfApp/app2/src/helpers/Analytics";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";
import { currentJobId, currentJobName, jobLoading } from "@rsfApp/app2/src/selectors/job.selectors";
import DocumentTab from "@rsfApp/app2/src/components/JobTabs/DocumentTab";
import ImageTab from "@rsfApp/app2/src/components/JobTabs/ImageTab";
import { ConnectedRouter } from "connected-react-router/immutable";
import SpinnerComponent from "@rsfApp/app2/src/components/SpinnerComponent";
import Homevest from "@rsfApp/app2/src/components/Homevest";
import { PaymentsModal } from "@rsfApp/app2/src/components/PaymentsModal";
import { SignaturesTab } from "@rsfApp/app2/src/components/JobTabs/SignaturesTab/index";
import AppointmentsTab from "@rsfApp/app2/src/components/JobTabs/AppointmentsTab";
import { MeasurementTab } from "@rsfApp/app2/src/components/JobTabs/MeasurementTab";
import { ProposalsTab } from "@rsfApp/app2/src/components/JobTabs/ProposalsTab";
import { currentOrgId, getTitle } from "@rsfApp/app2/src/selectors/org.selectors";
import { pathname } from "@rsfApp/app2/src/selectors/router.selectors";
import { ThunkDispatch } from "redux-thunk";
import { RootActions } from "@rsfApp/app2/src/reducers";
import { AsyncActions } from "@rsfApp/app2/src/reducers/components/common.actions";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { IPretty } from "app/src/Common/PrettyNameService";
import * as config from "react-global-configuration";
import NoteTab from "@rsfApp/app2/src/components/JobTabs/NoteTab";
import { PresentationTab } from "app2/src/Components/JobTabs/PresentationTab";
import { ContractsTab } from "@rsfApp/app2/src/components/JobTabs/ContractsTab";
import { EmailOrderCsvModal } from "@rsfApp/app2/src/components/JobTabs/EstimateTab/EmailOrderCsvModal";
import { ManualTriggerModal } from "@rsfApp/app2/src/components/JobTabs/EstimateTab/ManualTriggerModal";

const mapStateToProps = (state: RootState) => {
  return {
    jobLoading: jobLoading(state),
    jobId: currentJobId(state),
    orgId: currentOrgId(state),
    path: pathname(state),
    jobName: currentJobName(state),
    estimatorName: getTitle(state, { documentType: "estimator" }),
    estimateName: getTitle(state, { documentType: "estimate" }),
    contractName: getTitle(state, { documentType: "contract" }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: JobTabsProps) => {
  return {
    setPageTitle: (pageTitle: string) => {
      return dispatch(AsyncActions.setPageTitle(pageTitle));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface JobTabsProps {}

interface JobTabsState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & JobTabsProps;
@track(
  (props) => {
    return {
      component: "JobTabs",
      job: props.jobId,
      org: props.orgId,
    };
  },
  {
    dispatch: Dispatch.dispatch,
  }
)
class JobTabs extends React.Component<Props, JobTabsState> {
  constructor(props: Props) {
    super(props);
  }

  public componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<JobTabsState>, snapshot?: any): void {
    const pretty: IPretty = StoreRegistry.get("Pretty");
    const { path, jobName, estimatorName, setPageTitle, contractName, estimateName } = this.props;
    const page = path.split("/")[3];

    if (jobName && page) {
      let pageName;
      switch (page) {
        case "estimates":
          pageName = estimatorName;
          break;
        case "contracts":
          pageName = contractName + "s";
          break;
        case "proposals":
          pageName = estimateName + "s";
          break;

        default:
          pageName = pretty.name[page];
      }
      const pageTitle = `${jobName}: ${pageName} - ${config.get("APP_NAME_SHORT")}`;
      setPageTitle(pageTitle);
    }
  }

  public render() {
    const { jobLoading } = this.props;

    return (
      <ConnectedRouter history={history}>
        <Switch>
          <Route path={"/jobs/:jobId"}>{jobLoading ? <SpinnerComponent localProperty={true} /> : <JobRoutes />}</Route>
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default connector(JobTabs);

const JobRoutes = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route path={`${match.url}/info`} />
      <Route path={`${match.url}/appointments`} component={AppointmentsTab} />
      <Route path={`${match.url}/property`} />
      <Route path={`${match.url}/tools`} />
      <Route path={`${match.url}/images`} component={ImageTab} />
      <Route path={`${match.url}/documents`} component={DocumentTab} />
      <Route path={`${match.url}/measurements`} component={MeasurementTab} />
      <Route path={`${match.url}/notes`} component={NoteTab} />
      <Route path={`${match.url}/history`} />
      <Route path={`${match.url}/presentations`} component={PresentationTab} />
      <Route path={`${match.url}/design-tools`} />
      <Route path={`${match.url}/estimates/:estimateId/homevest`} component={Homevest} />
      <Route path={`${match.url}/estimates/:estimateId/email_order_csv`} component={EmailOrderCsvModal} />
      <Route path={`${match.url}/estimates/:estimateId/manual_trigger`} component={ManualTriggerModal} />
      <Route path={`${match.url}/estimates`} />
      <Route path={`${match.url}/price`} />
      <Route path={`${match.url}/:jobTab/:estimateId/payments`} component={PaymentsModal} />
      <Route path={`${match.url}/:jobTab/payments`} component={PaymentsModal} />
      <Route path={`${match.url}/proposals`} component={ProposalsTab} />
      <Route path={`${match.url}/inspection`} />
      <Route path={`${match.url}/contracts`} component={ContractsTab} />
      <Route path={`${match.url}/signatures`} component={SignaturesTab} />
      <Route path={`${match.url}`} render={() => <Redirect to={`${match.url}/info`} />} />
      <Route render={() => <Redirect to="/jobs" />} />
    </Switch>
  );
};
