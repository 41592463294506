import * as React from "react";
import _track, { Track, TrackingProp } from "react-tracking";
import { TrackingData } from "@rsfApp/app2/src/helpers/Analytics";
import { INoteResource } from "@rsfApp/app/src/Models/Note";
import { Modal, Row, Col, Button, FormControl } from "react-bootstrap";
import Spinner from "@rsfApp/app2/src/components/SpinnerComponent";

interface QuickNoteContainerProps {
  jobId: number;
  Note: INoteResource;
  close: () => void;
  open: boolean;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, QuickNoteContainerProps> = _track;

export interface QuickNoteContainerState {
  note: string;
  savingNote: boolean;
}

@track(
  (props: QuickNoteContainerProps) => {
    return {
      category: "Quick Note",
      action: "Show",
      job: props.jobId,
    };
  },
  { dispatchOnMount: false }
)
export default class QuickNoteContainer extends React.Component<QuickNoteContainerProps, QuickNoteContainerState> {
  public state = { note: "", savingNote: false };

  public render() {
    const { note, savingNote } = this.state;
    const { open } = this.props;
    return (
      <Modal show={open}>
        <Modal.Header>Add Job Note</Modal.Header>
        <Modal.Body>
          <Spinner localProperty={savingNote}>
            <Row>
              <Col sm={12}>
                <FormControl
                  as="textarea"
                  placeholder="Note"
                  value={note}
                  onChange={(event) => {
                    this.setState({ note: event.target.value });
                  }}
                  rows={5}
                />
              </Col>
            </Row>
          </Spinner>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col sm={12}>
              <Button variant="primary" className="pull-right" onClick={this.submit}>
                Save
              </Button>
              <Button variant="default" className="pull-right" onClick={this.close}>
                Cancel
              </Button>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    );
  }

  public submit = () => {
    const { Note, jobId, tracking } = this.props;
    const { note } = this.state;
    const newNote = new Note({ noteable_type: "jobs", noteable_id: jobId, note: note });

    tracking.trackEvent({ action: "Save New Note" });

    this.setState({ savingNote: true });
    newNote.$create().then(() => {
      this.setState({ savingNote: false });
      tracking.trackEvent({ action: "Save New Note Successful" });
      this.close();
    });
  };

  public close = () => {
    const { close } = this.props;
    close();
  };
}
