import { JobListCtrl } from "@rsfApp/app/src/Jobs/JobListCtrl";
import { IJobResource, IJob } from "@rsfApp/app/src/Models/Job";
import { ISession } from "@rsfApp/app/src/Common/SessionService";
import { EstimatorService } from "@rsfApp/app/src/Estimator/EstimatorService";
import * as angulartics from "angulartics";
import { IAdInfo } from "@rsfApp/app/src/Common/AdService";
import { IFlash } from "@rsfApp/app/src/Common/FlashService";
import { IEventResource } from "@rsfApp/app/src/Models/Event";
import * as moment from "moment";
import * as ng from "angular";
import { IPretty } from "../Common/PrettyNameService";
import { Actions as commonActions } from "@rsfApp/app2/src/reducers/components/common.actions";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";

export class GafJobListCtrl extends JobListCtrl {
  public search = "";
  public eventResponse: any;
  public options: any = {
    chart: {
      type: "pieChart",
      donut: true,
      height: 200,
      showLabels: false,
      x: function (d) {
        return d.key;
      },
      y: function (d) {
        return d.y;
      },
      color: function (d) {
        return d.color;
      },
      legendPosition: "right",
      donutRatio: 0.4,
      enable: false,
      showLegend: false,
      tooltip: {
        enabled: false,
      },
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
  public dataGen: any = [
    {
      key: "New",
      y: 50,
      color: "#FF0000",
    },
  ];
  public expandFilter = false;

  public static $inject = [
    "$stateParams",
    "Job",
    "Session",
    "$scope",
    "$timeout",
    "EstimatorService",
    "AdService",
    "Flash",
    "Event",
    "$analytics",
    "$window",
    "$state",
    "Pretty",
  ];
  constructor(
    public $stateParams: ng.ui.IStateParamsService,
    public Job: IJobResource,
    public Session: ISession,
    public $scope: ng.IScope,
    public $timeout: ng.ITimeoutService,
    public EstimatorService: EstimatorService,
    public AdService: IAdInfo,
    public Flash: IFlash,
    public Event: IEventResource,
    public $analytics: angulartics.IAnalyticsService,
    public $window: ng.IWindowService,
    public $state: ng.ui.IStateService,
    public Pretty: IPretty
  ) {
    super($stateParams, Job, Session, $scope, $timeout, EstimatorService, AdService, Flash, $analytics);

    this.eventResponse = this.Event.query({
      start: moment().format(),
      end: moment().add(2, "days").endOf("day").format(),
      jobs_only: true,
      "include[]": ["job_basic"],
    });
    this.promise.then(() => {
      this.setupStats();
    });
    this.gafStateName = this.gafStateName.bind(this);
    this.toggleStateFilter = this.toggleStateFilter.bind(this);
    this.colorFromRawState = this.colorFromRawState.bind(this);
    this.stateSelected = this.stateSelected.bind(this);
    this.includeArchivedQuery = this.includeArchivedQuery.bind(this);
    this.setJobTypeFilter = this.setJobTypeFilter.bind(this);
    this.toggleFilter = this.toggleFilter.bind(this);
    this.searchChanged = this.searchChanged.bind(this);
  }

  public includeArchivedQuery(archive) {
    this.archived = archive;
    this.refreshQuery();
  }

  public jobListPartialUrl(settings_acl) {
    let view_type = "card_view";
    if (!this.jobListCardView) {
      view_type = "list_view";
    }

    return `gaf/src/Jobs/partials/${view_type}/${settings_acl.sort().join("_")}_list.html`;
  }

  public searchChanged() {
    this.refreshQuery();
  }

  public setView(value) {
    this.$analytics.eventTrack("toggle display", { category: "Job List", jobListCardView: this.jobListCardView });
    this.jobListCardView = value;
  }

  public setupStats() {
    this.dataGen = [];
    _.each(this.states, (value: string, key: string) => {
      this.dataGen.push({
        key: value,
        y: this.jobResponse.stats[key] || 0,
        color: this.colorFromRawState(key),
      });
    });
    this.$timeout(() => {
      this.$window.dispatchEvent(new Event("resize"));
      this.$scope.$digest();
    });
  }

  public toggleFilter() {
    this.expandFilter = !this.expandFilter;
  }

  public gafStateName(rawState: string, state: string) {
    const prettyName = this.Pretty.name[state];
    if (prettyName) {
      return prettyName;
    }

    return this.nameFromRawState(rawState);
  }

  public incrementShowCsvModal() {
    StoreRegistry.getStore().dispatch(commonActions.openImportModal());
  }

  public goToInfo(job_id) {
    this.$state.go("job_header.job_show.info", { id: job_id });
  }

  public setJobTypeFilter(id: number) {
    this.jobTypeId = id;
    this.refreshQuery();
  }
}
