import * as React from "react";
import { FormControl, Card, Accordion, Row, Col, Form } from "react-bootstrap";
import { IEstimateLineItem, IsOptionSelected } from "@rsfApp/app/src/Models/EstimateLineItem";
import { AddLineItemOption, IEstimate, RemoveLineItemOption } from "@rsfApp/app/src/Models/Estimate";
import { CheckCircle } from "./CheckCircle";
import { RadioCircle } from "./RadioCircle";
import inspectionService from "./Inspection.service";
import Files from "react-files";
import { ConfirmDialog } from "@rsfApp/app2/src/components/Common/ConfirmDialog";
import { Nullable } from "@rsfApp/app2/src/records";
import { IFileQueueFactory } from "@rsfApp/app/src/Common/FileQueueFactory";
import { IImage } from "@rsfApp/app/src/Models/Image";
import { ImagePickerModal } from "@rsfApp/app2/src/components/Common/ImagePickerModal";
import { connect, ConnectedProps } from "react-redux";
import { ProductOptionGroupRecord } from "@rsfApp/app2/src/records/ProductOptionGroup";
import { List } from "immutable";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { ThunkDispatch } from "redux-thunk";
import { cachedProductOptionGroups } from "@rsfApp/app2/src/selectors/productOptionGroup.selectors";
import { cachedProductOptions } from "@rsfApp/app2/src/selectors/productOption.selectors";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { ProductOptionRecord } from "@rsfApp/app2/src/records/ProductOption";

const mapStateToProps = (state: RootState, ownProps: IInspectionItemProps) => {
  return {
    optionGroups: cachedProductOptionGroups(state, {
      activatedPriceListId: ownProps.activatedPriceListId,
      productId: ownProps.lineItem.product_id,
    }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IInspectionItemProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export interface IInspectionItemProps {
  activatedPriceListId: number;
  selectedEventKey: Nullable<string>;
  lineItem: IEstimateLineItem;
  queue: IFileQueueFactory;
  triggerExpand: (key: string) => void;
  check: any;
  estimate: IEstimate;
}

export interface IInspectionItemState {
  lineItem: IEstimateLineItem;
  showImagePickerModal: boolean;
}

function mapStateToPropsImagePicker(state) {
  const currentJob = state.getIn(["components", "common", "currentJob"]);
  const jobImages = state.getIn(["jobs", "byId", currentJob.id, "images"]);
  return {
    images: jobImages,
  };
}

const ConnectedImagePickerModal = connect(mapStateToPropsImagePicker)(ImagePickerModal);

interface ReduxProps {
  optionGroups: List<ProductOptionGroupRecord>;
}

interface DispatchProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IInspectionItemProps;

class InspectionItem extends React.Component<Props, IInspectionItemState> {
  constructor(props) {
    super(props);

    this.state = {
      lineItem: props.lineItem,
      showImagePickerModal: false,
    };
    this.closeModal = this.closeModal.bind(this);
  }

  public render() {
    const { selectedEventKey, triggerExpand, check, activatedPriceListId, optionGroups, estimate } = this.props;
    const { showImagePickerModal } = this.state;
    let { lineItem } = this.state;

    const conditionGroup = optionGroups.find((og) => og.name === "Answers");
    const conditionGroupOptions = cachedProductOptions(StoreRegistry.getStore().getState(), {
      activatedPriceListId,
      productOptionIds: conditionGroup.option_ids,
    });
    const goodConditionPo = conditionGroupOptions.find((o) => o.name === "Satisfactory");
    const issuesGroup = optionGroups.find((og) => og.name === "Needs Attention");
    const issuesGroupOptions = cachedProductOptions(StoreRegistry.getStore().getState(), {
      activatedPriceListId,
      productOptionIds: issuesGroup.option_ids,
    });

    if (!lineItem) {
      return;
    }

    return (
      <Card>
        <ConnectedImagePickerModal
          show={showImagePickerModal}
          closeHandler={this.closeModal}
          selectionHandler={this.addImageToItem}
          selectMultiple={true}
        />
        <Accordion.Toggle as={Card.Header} eventKey={lineItem.id.toString()}>
          <Row>
            <Col
              className={IsOptionSelected(lineItem, goodConditionPo) ? "title-grey" : "title-red"}
              sm={12}
              md={{ span: 4, offset: 1 }}
              lg={{ span: 3, offset: 2 }}>
              {lineItem.name}
            </Col>
            {conditionGroupOptions.map((po: ProductOptionRecord, idx: number) => {
              return (
                <Col sm={6} md={2} key={idx} className="center-checkbox">
                  <RadioCircle
                    color={idx === 0 ? "green" : ""}
                    name={lineItem.id.toString()}
                    id={po.id.toString()}
                    onChange={(value: boolean) => {
                      triggerExpand(lineItem.id.toString());
                      this.setState({
                        lineItem: AddLineItemOption(lineItem, po, conditionGroup, 0, estimate),
                      });
                      check();
                    }}
                    checked={IsOptionSelected(lineItem, po)}
                  />
                </Col>
              );
            })}
            <Col sm={6} md={2} className="center-checkbox">
              <div
                onClick={(e) => {
                  triggerExpand(lineItem.id.toString());
                  e.stopPropagation();
                }}>
                <Files
                  className="files-dropzone center-checkbox"
                  onChange={this.onFilesUpload}
                  onError={this.onFilesError}
                  accepts={["image/*", ".pdf"]}
                  multiple={false}
                  maxFileSize={30000000}
                  minFileSize={0}
                  clickable>
                  <i className="fa fa-camera fa-2x" />
                </Files>
              </div>
              <div
                onClick={(e) => {
                  this.setState({ showImagePickerModal: !this.state.showImagePickerModal });
                  e.stopPropagation();
                }}
                style={{ width: "36px", height: "36px" }}>
                <i className="fa fa-folder fa-2x" />
              </div>
            </Col>
            <Col sm={6} md={1}>
              <i className={`fa ${selectedEventKey === lineItem.id.toString() ? "fa-angle-up" : "fa-angle-down"}`} />
            </Col>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={lineItem.id.toString()}>
          <Card.Body>
            <Row>
              <Col className="title" sm={12} md={{ span: 4, offset: 1 }} lg={{ span: 3, offset: 2 }}>
                <RadioVertical
                  lineItem={lineItem}
                  options={issuesGroupOptions}
                  onChange={(po, value) => {
                    if (value) {
                      lineItem = AddLineItemOption(lineItem, po, issuesGroup, 0, estimate);
                    } else {
                      lineItem = RemoveLineItemOption(lineItem, po.uuid);
                    }
                    check();
                    this.setState({ lineItem });
                  }}
                />
              </Col>
              <Col sm={6}>
                <Row>
                  <Col sm={12}>
                    <FormControl
                      as="textarea"
                      placeholder="Notes"
                      value={lineItem.description}
                      onChange={(event) => {
                        lineItem.description = event.target.value;
                        check();
                        this.setState({ lineItem });
                      }}
                      rows={5}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="form-group add-to-presentation pull-right">
                      <label>Add to Agreement</label>
                      <div className="toggle-container">
                        <input
                          type="checkbox"
                          checked={lineItem.visibility === "a" || lineItem.visibility === "ab"}
                          onChange={(event) => this.inclusionChanged("a", event.target.checked)}
                          className="toggle form-control"
                          id="switch-normal"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <div className="form-group add-to-presentation pull-right">
                      <label>Add to Presentation</label>
                      <div className="toggle-container">
                        <input
                          type="checkbox"
                          checked={lineItem.visibility === "b" || lineItem.visibility === "ab"}
                          onChange={(event) => this.inclusionChanged("b", event.target.checked)}
                          className="toggle form-control"
                          id="switch-normal"
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                className="inspection-image-container"
                sm={12}
                md={{ span: 10, offset: 1 }}
                lg={{ span: 9, offset: 2 }}>
                {lineItem.images
                  .filter((i) => i._destroy !== true)
                  .map((i, idx) => {
                    return (
                      <div key={idx} className="photo">
                        <img className="inspection-image-thumb" src={i.getUrl("small")} />
                        <ConfirmDialog description="" title={"Are you sure you want to delete this photo?"}>
                          {(confirm) => (
                            <i
                              className="rsf-delete-link rsf-base-66 action-button"
                              onClick={confirm(() => this.deletePhoto(i))}
                            />
                          )}
                        </ConfirmDialog>
                      </div>
                    );
                  })}
              </Col>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  /*
   * "a" -> Include on Agreement
   * "b" -> Include on Presentation
   */
  protected inclusionChanged = (prop: "a" | "b", value: boolean) => {
    const { lineItem } = this.state;
    const { check } = this.props;

    this.setState({
      lineItem: inspectionService.setVisibility(lineItem, prop, value),
    });
    check();
  };

  protected setPhotoInclusion = (image: IImage, include: boolean) => {
    const { lineItem } = this.state;
    const { check } = this.props;

    inspectionService.setInclusion(image, include);
    check();

    // trigger a render as obj state has changed
    this.setState({ lineItem });
  };

  protected onFilesUpload = (files) => {
    const { queue, check } = this.props;
    const { lineItem } = this.state;

    if (files.length > 0) {
      queue.getObject(files[0], lineItem).then(() => {
        check();
        this.setState({ lineItem });
        // queue.mergeQueue(lineItem, queue.temp_queue);
        queue.setQueue();
      });
    }
  };

  protected closeModal = () => {
    this.setState({
      showImagePickerModal: false,
    });
  };

  protected addImageToItem = async (images) => {
    await Promise.all(
      images.map(async (image) => {
        const imgBlob = await fetch(image.file.url).then((resp) => resp.blob());
        const file = new File([imgBlob], image.name);
        this.onFilesUpload([file]);
      })
    );
  };

  protected deletePhoto = (image: IImage) => {
    const { lineItem } = this.state;
    const { check } = this.props;

    if (image.id >= 0) {
      image._destroy = true;
    } else {
      lineItem.images = lineItem.images.filter((i) => i.id !== image.id);
    }

    check();
    this.setState({ lineItem });
  };

  protected onFilesError = (error, file) => {
    console.error(error);
  };
}

export default connector(InspectionItem);

interface IRadioVerticalProps {
  options: List<ProductOptionRecord>;
  lineItem: IEstimateLineItem;
  onChange: (po, selected: boolean) => void;
}

const RadioVertical: React.FunctionComponent<IRadioVerticalProps> = (props) => {
  const { lineItem, onChange, options } = props;
  return (
    <Row>
      <Col sm={12}>
        {options.map((po, idx) => {
          return (
            <Row key={idx}>
              <Col sm={12}>
                <Form.Group className="row">
                  <CheckCircle
                    column
                    onChange={(value) => onChange(po, value)}
                    checked={IsOptionSelected(lineItem, po)}
                  />
                  <Form.Label column sm="10">
                    {po.name}
                  </Form.Label>
                </Form.Group>
              </Col>
            </Row>
          );
        })}
      </Col>
    </Row>
  );
};
