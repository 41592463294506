import * as config from "react-global-configuration";
import * as React from "react";
import { Row, Col, Button } from "react-bootstrap";

export interface IInvalidCredentialsContainerProps {}

export default function InvalidCredentialsContainer(_props: IInvalidCredentialsContainerProps) {
  return (
    <React.Fragment>
      <Row className="h-100 align-items-center">
        <Col sm={12} className="text-center">
          <h3>The credentials used to sign into the system are invalid.</h3>
          <br />
          <Button variant="default" href="/logout">
            Sign out of GAF SSO
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
