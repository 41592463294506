import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import SpinnerComponent from "@rsfApp/app2/src/components/SpinnerComponent";
import * as authActions from "@rsfApp/app2/src/reducers/auth.actions";
import * as userActions from "@rsfApp/app2/src/reducers/user.actions";
import { IUserData } from "@rsfApp/app2/src/records/UserRecord";
import { ISession } from "@rsfApp/app/src/Common/SessionService";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { IAuthService } from "@rsfApp/app2/src/helpers/Auth.service";

const mapStateToProps = (state: RootState, ownProps: GafCallbackContainerProps) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: GafCallbackContainerProps
) => {
  return {
    setToken: (token: string) => {
      return dispatch(authActions.Actions.setToken(token));
    },
    loadUser: (email: string, token?: string) => {
      return dispatch(userActions.AsyncActions.getCurrentUserByEmail(email, token));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

const gafAuth: IAuthService = StoreRegistry.get("authService");

interface GafCallbackContainerProps {
  $state: ng.ui.IStateService;
  Session: ISession;
}

export interface GafCallbackContainerState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & GafCallbackContainerProps;

class GafCallbackContainer extends React.Component<Props, GafCallbackContainerState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public async componentDidMount() {
    const { $state, Session, setToken, loadUser } = this.props;

    try {
      const v = await (gafAuth as any).handleRedirect();
      const u = await loadUser(v.user.email, v.token);
      gafAuth.setCurrentUser(u.id, u.email, v.token);

      Session.updateBilling();
      //@ts-ignore
      Session.setupReduxUser();

      setToken(v.token);
      //@ts-ignore
      Session.loadPreference();
      if (v.appState.goToRoute) {
        $state.go(v.appState.goToRoute, v.appState.goToParams);
      } else {
        $state.go("root.job_list");
      }
    } catch (err) {
      console.error("Auth Error: ", err);
      $state.go("invalid");
    }
  }

  public render() {
    return <SpinnerComponent localProperty={true} />;
  }
}

export default connector(GafCallbackContainer);
