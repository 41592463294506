import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { UserRecord } from "@rsfApp/app2/src/records/UserRecord";
import { Row, Col } from "react-bootstrap";

const mapStateToProps = (state: RootState, ownProps: ISalesProcessProps) => {
  return {
    currentUser: state.getIn(["users", "currentUser"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ISalesProcessProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ISalesProcessProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ISalesProcessProps;

export interface ISalesProcessState {
  activeTab: string;
}

class SalesProcess extends React.Component<Props, ISalesProcessState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: "tab1",
    };

    this.selectTab = this.selectTab.bind(this);
  }

  public selectTab(event) {
    this.setState({
      activeTab: event.currentTarget.id,
    });
  }

  public tabContent() {
    const { activeTab } = this.state;

    switch (activeTab) {
      case "tab1":
        return (
          <Col>
            <Row>
              <Col className="header col-3">Actions</Col>
              <Col className="header col-9">What to Do/Use</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Look Professional</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Proper attire</li>
                  <li>Logo or name tag</li>
                  <li>Clipboard in hand</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Be Respectful</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>On time, park on street</li>
                  <li>Knock on door vs. ring doorbell</li>
                  <li>Take off boots/bring booties</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Build Rapport</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Smile</li>
                  <li>Respectful intro</li>
                  <li>F.O.R. (Family, Occupation, Recreation)</li>
                  <li>Mention payment options</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Gather Information</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Use prepared questions</li>
                  <li>Listen and take notes</li>
                  <li>Understand likely shingle choices</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Share Agenda</Col>
              <Col className="what-to-do col-9">
                Share brief agenda/timeline:
                <ul className="styled">
                  <li>Your needs</li>
                  <li>About us</li>
                  <li>Solutions</li>
                  <li>Next steps</li>
                </ul>
              </Col>
            </Row>
          </Col>
        );
      case "tab2":
        return (
          <Col>
            <Row>
              <Col className="header col-3">Actions</Col>
              <Col className="header col-9">What to Do/Use</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Inspect, Take Photos</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Interior — Identify fragile items, pets, etc.</li>
                  <li>Exterior — Walk around with homeowner; identify landscaping, job site needs</li>
                  <li>Attic — Assess ventilation, deck</li>
                  <li>Roof — Inspect by preferred method</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Measure Roof</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Use your preferred method</li>
                  <li>
                    Consider making a great impression with{" "}
                    <strong>GAF QuickMeasure, EagleView, a Loveland Drone or GAF E360</strong>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Organize Findings</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Select relevant pictures and videos</li>
                  <li>Start Preparing Estimate</li>
                  <li>
                    Assemble brochures/samples <strong>(Prep GAF System Selling bag)</strong>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        );
      case "tab3":
        return (
          <Col>
            <Row>
              <Col className="header col-3">Actions</Col>
              <Col className="header col-9">What to Do/Use</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Share Your Story</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>GAF Certification/CARE training</li>
                  <li>GAF story</li>
                  <li>Your company story and training</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Findings</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Describe what your inspection revealed</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Demonstrate Roof System</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Share literature</li>
                  <li>
                    Use <strong>GAF Brochures</strong>, the <strong>GAF System Selling Bag</strong> and items in it
                  </li>
                  <li>Emphasize solutions to issues discovered</li>
                  <li>Incorporate warranty</li>
                  <li>Shingle and aesthetics</li>
                  <li>Confirm system choice</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Home Aesthetics</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>What aesthetics are they looking for?</li>
                  <li>
                    Show <strong>GAF Brochures</strong> colors, styles, etc.
                  </li>
                  <li>
                    Use <strong>GAF Virtual Home Remodeler</strong> app
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Educate on Warranties</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>System vs. shingle</li>
                  <li>Lifetime vs. extended (Use paper to illustrate how warranties work)</li>
                  <li>Workmanship vs. labor</li>
                  <li>Confirm system and warranty</li>
                </ul>
              </Col>
            </Row>
          </Col>
        );
      case "tab4":
        return (
          <Col>
            <Row>
              <Col className="header col-3">Actions</Col>
              <Col className="header col-9">What to Do/Use</Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Recap Highlights</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Change body language</li>
                  <li>Repeat what's important to them</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Present Offer</Col>
              <Col className="what-to-do col-9">
                Use template to present options:
                <ul className="styled">
                  <li>Better/Basic</li>
                  <li>Price/Payment Options that show Financing</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Offer Payment Options</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>
                    Use the <strong>GAF SmartMoney</strong> platform for a seamless, professional close
                  </li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Respond to Objections</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Price too high...</li>
                  <li>Want to think it over...</li>
                  <li>Getting more estimates...</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Close Sale</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Walk through contract</li>
                  <li>Finance application</li>
                </ul>
              </Col>
            </Row>
            <Row className="align-items-center">
              <Col className="action col-3">Warm Down</Col>
              <Col className="what-to-do col-9">
                <ul>
                  <li>Set expectations</li>
                  <li>Confirming selections</li>
                  <li>Review what to expect with your new roof</li>
                  <li>Preview referral process/everybody knows a roofer</li>
                  <li>Exit with personal commentary from building rapport</li>
                </ul>
              </Col>
            </Row>
          </Col>
        );
    }
  }

  public render() {
    const { currentUser } = this.props;
    const content = this.tabContent();
    return (
      <Row className="sales-process-page">
        <Col>
          <Row className="tabs">
            <Col className={`tab ${this.state.activeTab}`} id="tab1" onClick={this.selectTab}>
              <h5 className="title">Start Smart</h5>
              <p className="subtitle">Help the customer perceive you as likeable, credible, and trustworthy</p>
              <h4 className="number">01</h4>
            </Col>
            <Col className={`tab ${this.state.activeTab}`} id="tab2" onClick={this.selectTab}>
              <h5 className="title">Analyze Needs</h5>
              <p className="subtitle">Help the customer perceive their problem must be solved now</p>
              <h5 className="number">02</h5>
            </Col>
            <Col className={`tab ${this.state.activeTab}`} id="tab3" onClick={this.selectTab}>
              <h5 className="title">Offer Solutions</h5>
              <p className="subtitle">
                Help the customer see that you can solve their problems better than other options
              </p>
              <h5 className="number">03</h5>
            </Col>
            <Col className={`tab ${this.state.activeTab}`} id="tab4" onClick={this.selectTab}>
              <h5 className="title">Close the Sale</h5>
              <p className="subtitle">
                Help your customer perceive that your price is lower than your value and your teams make it "easy to
                buy"
              </p>
              <h5 className="number">04</h5>
            </Col>
          </Row>
          <Row className="content">{content}</Row>
        </Col>
      </Row>
    );
  }
}

export default connector(SalesProcess);
