import * as React from "react";
import { Row, Col } from "react-bootstrap";

interface INoTemplatesComponentProps {}

const NoTemplatesComponent: React.FunctionComponent<INoTemplatesComponentProps> = (props) => {
  return (
    <Row>
      <Col sm={12}>There are no Presentation Templates. Please create one.</Col>
    </Row>
  );
};

export default NoTemplatesComponent;
