import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { List } from "immutable";
import { Card, Modal, Button, Row, Col } from "react-bootstrap";
import { PresentationTemplateRecord } from "@rsfApp/app2/src/records/PresentationTemplate";
import { getOrgPresentationTemplates } from "./presentationTemplateSelector";
import NoTemplateComponent from "./NoTemplatesComponent";
import SpinnerComponent from "@rsfApp/app2/src/components/SpinnerComponent";

const mapStateToProps = (state: RootState, ownProps: ListPickerProps) => {
  return {
    presentationTemplates: getOrgPresentationTemplates(state, { orgId: ownProps.orgId }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ListPickerProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ListPickerProps {
  orgId: number;
  show: boolean;
  onSelect: (template: PresentationTemplateRecord) => void;
  onClose: () => void;
}

export interface ListPickerState {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ListPickerProps;

class ListPicker extends React.Component<Props, ListPickerState> {
  public render() {
    const { onClose, orgId, presentationTemplates } = this.props;

    return (
      <SpinnerComponent stateProperty={["presentationTemplates", "presentationTemplatesByOrgId", orgId, "loading"]}>
        <Modal size="lg" show={this.props.show} onHide={onClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              <div className="row-eq-height">
                <Col md={12}>
                  <h3>Presentation Templates</h3>
                </Col>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col sm={12}>{this.renderTemplates(presentationTemplates)}</Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose}>Close</Button>
          </Modal.Footer>
        </Modal>
      </SpinnerComponent>
    );
  }

  public renderTemplates = (templates: List<PresentationTemplateRecord>) => {
    const { onSelect } = this.props;
    if (templates.size <= 0) {
      return <NoTemplateComponent />;
    }

    return templates.map((t, idx) => {
      return (
        <Card key={idx}>
          <Card.Body onClick={(e) => onSelect(t)}>{t.name}</Card.Body>
        </Card>
      );
    });
  };
}

export default connector(ListPicker);
