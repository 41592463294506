import { ISession } from "@rsfApp/app/src/Common/SessionService";
export class GafLogoutCtrl {
  public static $inject = ["$state", "Session", "User"];

  constructor(private $state: ng.ui.IStateService, private Session: ISession) {
    if (Session.hasToken()) {
      Session.logout();
    } else {
      $state.go("root.logged_out");
    }
  }
}
