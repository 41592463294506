import { store } from "@rsfApp/app2/src/store";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import * as authActions from "@rsfApp/app2/src/reducers/auth.actions";
import { cognitoAuth } from "./Authentication/CognitoAuth.service";
import { remoteStorage } from "@rsfApp/app2/src/api/remoteStorage.service";

StoreRegistry.setStore(store);
StoreRegistry.set("window", window);
StoreRegistry.set("authService", cognitoAuth);
StoreRegistry.set("remoteStorage", remoteStorage);

// load any saved authentication information
store.dispatch(
  authActions.Actions.setTokens({
    token: cognitoAuth.getToken(),
    refreshToken: "",
  })
);
