import * as config from "react-global-configuration";
import * as React from "react";

import { Row, Col, Button } from "react-bootstrap";

export interface ILoggedOutContainerProps {}

export default function LoggedOutContainer(_props: ILoggedOutContainerProps) {
  const email = sessionStorage.getItem("LogoutAppState");

  if (email.toLowerCase().endsWith("gaf.com") || email.toLowerCase().endsWith("gaf.energy")) {
    (window.location as any) = `https://standardindustries.okta-emea.com/login/signout?fromURI=${config.get(
      "GAF_OKTA_LOGOUT_URL"
    )}`;
  }

  return (
    <React.Fragment>
      <Row className="h-100 align-items-center">
        <Col sm={12} className="text-center">
          <h3>You have been successfully logged out of GAF Project.</h3>
          <h3>It is now safe to close this window.</h3>
          <br />
          <Button variant="default" href="/">
            Sign In
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
}
