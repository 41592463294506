import * as React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { InspectionSlides } from "./InspectionSlides";
import { Nullable } from "@rsfApp/app2/src/records";

export interface IInspectionComponentProps {
  inspectionId: Nullable<number>;
}

const InspectionComponent: React.FC<IInspectionComponentProps> = ({ inspectionId }) => {
  return (
    <Row>
      <Col sm={12}>
        <Card>
          <Card.Header>Inspection Items (Analyze Needs)</Card.Header>
          <Card.Body>
            <Row>
              <Col sm={12} className="slide-container">
                {inspectionId ? <InspectionSlides inspectionId={inspectionId} /> : <span>No Inspection</span>}
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default InspectionComponent;
