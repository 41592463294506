import { GafLoginCtrl } from "./GafLoginCtrl";
import { GafLogoutCtrl } from "./GafLogoutCtrl";
import { react2angular } from "react2angular";
import GafCallbackContainer from "app2/src/Authentication/GafCallbackContainer";
import CognitoCallbackContainer from "app2/src/Authentication/CognitoCallbackContainer";
import LoggedOutContainer from "app2/src/Authentication/LoggedOutContainer";
import InvalidCredentialsContainer from "app2/src/Authentication/InvalidCredentialsContainer";
import { LeadsAuthComponent } from "app2/src/Authentication/LeadsAuthComponent";

export class GafLoginRoutes {
  public static load(app: ng.IModule) {
    app.controller("LoginCtrl", GafLoginCtrl);
    app.controller("LogoutCtrl", GafLogoutCtrl);
    app.component("gafAuthCallback", react2angular(GafCallbackContainer, [], ["$state", "Session"]));
    app.component("cognitoAuthCallback", react2angular(CognitoCallbackContainer, [], ["$state", "Session"]));
    app.component("loggedOutContainer", react2angular(LoggedOutContainer));
    app.component("invalidCredentialsContainer", react2angular(InvalidCredentialsContainer));
    app.component("leadsAuthComponent", react2angular(LeadsAuthComponent));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("root.login", <ng.ui.IState>{
        url: "/login",
        views: {
          "header@": {},
          "container@": {
            templateUrl: "gaf/src/Auth/login.html",
            controller: "LoginCtrl",
            controllerAs: "ctrl",
          },
        },
        params: {
          routeTo: null,
          routeParams: null,
        },
      })
      .state("leads_auth", <ng.ui.IState>{
        url: "/leads_module",

        views: {
          "header@": {},
          "container@": {
            component: "leadsAuthComponent",
          },
        },
      })
      .state("root.logout", <ng.ui.IState>{
        url: "/logout",
        views: {
          "container@": {
            templateUrl: "src/Login/logout.html",
            controller: "LogoutCtrl",
            controllerAs: "ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("logged_out", <ng.ui.IState>{
        url: "/auth/logout",
        views: {
          "container@": {
            component: "loggedOutContainer",
          },
        },
      })
      .state("invalid", <ng.ui.IState>{
        url: "/auth/invalid",
        views: {
          "container@": {
            component: "invalidCredentialsContainer",
          },
        },
      })
      .state("callback", <ng.ui.IState>{
        url: "/auth/callback",
        views: {
          "container@": {
            component: "gafAuthCallback",
          },
        },
      })
      .state("occ_callback", <ng.ui.IState>{
        url: "/occ_callback",
        views: {
          "container@": {
            component: "cognitoAuthCallback",
          },
        },
      })
      .state("root.terms_and_conditions", <ng.ui.IState>{
        url: "/terms",
        views: {
          "container@": {
            templateUrl: "src/Login/terms_and_conditions.html",
          },
        },
      })
      .state("root.screen_share_help", <ng.ui.IState>{
        url: "/screen-share-help",
        views: {
          "container@": {
            templateUrl: "src/Login/screen_share_help.html",
          },
        },
      });
  }
}
