import { capitalize } from "humanize-plus";
import { OrgShowCtrl } from "@rsfApp/app/src/Orgs/OrgShowCtrl";
import { capitalizeString } from "@rsfApp/app2/src/services/string.service";

export class GafOrgShowCtrl extends OrgShowCtrl {
  public navTitle() {
    let title = "";

    switch (this.$state.current.name) {
      case "root.org_show.info":
        title = "Organization Information";
        break;
      case "root.org_show.settings":
        title = "Settings";
        break;
      case "root.org_show.preferences":
        title = "Preferences";
        break;
      case "root.org_show.setup":
        title = "Setup";
        break;
      case "root.org_show.notifications":
        title = "Notifications";
        break;
      case "root.org_show.billing":
        title = "Billing";
        break;
      case "root.org_show.integrations":
        title = "Integrations";
        break;
      case "root.org_show.orgs":
        title = "Child Organizations";
        break;
      case "root.org_show.users":
        title = "Users";
        break;
      case "root.org_show.jobs":
        title = "Jobs";
        break;
      case "root.org_show.documents":
        title = "Documents";
        break;
      case "root.org_show.presentations":
        title = "Presentations";
        break;
      case "root.org_show.price_lists":
        title = "Price Lists";
        break;
      case "root.org_show.price_list_header_list":
      case "root.org_show.price_list_header.editor":
        title = "Price List Editor";
        break;
      case "root.org_show.patterns":
        title = "Patterns";
        break;
      case "root.org_show.measurement_links":
        title = "Measurement Links";
        break;
      case "root.org_show.payment_terms":
        title = "Payment Terms";
        break;
      case "root.org_show.stats":
        title = "Statistics";
        break;
      case "root.org_show.emails":
        title = "Email History";
        break;
      case "root.org_show.discounts":
        title = "Discounts";
        break;
      case "root.org_show.duplicate_data":
        title = "Duplicate Data";
        break;
      case "root.org_show.react-org-tabs":
        const page = this.$state.params.path?.split("/")[0];
        title = page
          .split("_")
          .map((word) => capitalizeString(word))
          .join(" ");

        break;
    }
    return title;
  }
}
