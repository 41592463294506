import { Amplify, ResourcesConfig } from "aws-amplify";
import { fetchAuthSession, signInWithRedirect, signOut, getCurrentUser, JWT } from "aws-amplify/auth";
import * as config from "react-global-configuration";
import { IUser } from "@rsfApp/app/src/Models/User";
import { IAuthService } from "@rsfApp/app2/src/helpers/Auth.service";
import * as authActions from "@rsfApp/app2/src/reducers/auth.actions";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { Hub } from "aws-amplify/utils";
import * as userActions from "@rsfApp/app2/src/reducers/user.actions";
import { ISession } from "rsf-app/packages/rsf-app/app/src/Common/SessionService";

Hub.listen("auth", ({ payload }) => {
  switch (payload.event) {
    case "signedIn":
      // console.info("user have been signedIn successfully.");
      break;
    case "signedOut":
      // console.info("user have been signedOut successfully.");
      break;
    case "tokenRefresh":
      // console.info("auth tokens have been refreshed.");
      break;
    case "tokenRefresh_failure":
      console.error("failure while refreshing auth tokens.");
      break;
    case "signInWithRedirect":
      // console.info("signInWithRedirect API has successfully been resolved.", payload);
      break;
    case "signInWithRedirect_failure":
      console.error("failure while trying to resolve signInWithRedirect API.", payload);
      break;
    case "customOAuthState":
      // console.info("custom state returned from CognitoHosted UI");
      break;
  }
});
const oauth: ResourcesConfig = {
  Auth: {
    Cognito: {
      //  Amazon Cognito User Pool ID
      userPoolId: config.get("COGNITO_USER_POOL_ID"),
      identityPoolId: config.get("COGNITO_IDENTITY_POOL_ID"),
      // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
      userPoolClientId: config.get("COGNITO_USER_POOL_CLIENT_ID"),
      // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
      allowGuestAccess: false,
      // OPTIONAL - This is used when autoSignIn is enabled for Auth.signUp
      // 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
      signUpVerificationMethod: "code", // 'code' | 'link'
      loginWith: {
        // OPTIONAL - Hosted UI configuration
        oauth: {
          domain: config.get("COGNITO_DOMAIN"),
          scopes: ["email", "profile", "phone", "openid"],
          redirectSignIn: [config.get("COGNITO_REDIRECT_SIGN_IN")],
          redirectSignOut: [config.get("COGNITO_REDIRECT_SIGN_OUT")],
          responseType: "code", // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
      },
    },
  },
};

Amplify.configure(oauth);

class CongitoAuth implements IAuthService {
  public token: string;
  private _user: any;
  private _jwt: JWT;
  private _idt: JWT;
  private _refresher: any;

  public hasToken(): boolean {
    return !!this.token;
  }
  public getToken(): string {
    return this.token;
  }

  public hasRefreshToken(): boolean | "" {
    return !!this.token;
  }
  public getRefreshToken(): string {
    return "";
  }
  public setRefreshToken(token: string): void {}

  public hasAccessUid = () => {
    const rsfAuth = sessionStorage.getItem("rsf-auth");
    if (!rsfAuth || rsfAuth === "null" || rsfAuth === "undefined") {
      return "";
    }

    try {
      const rsfAuthObj = JSON.parse(rsfAuth);
      return !_.isEmpty(rsfAuthObj.accessUid);
    } catch (e) {
      return false;
    }
  };

  public getAccessUid = () => {
    if (!this.hasAccessUid()) {
      return "";
    }

    const rsfAuth = sessionStorage.getItem("rsf-auth");
    const rsfAuthObj = JSON.parse(rsfAuth || "{}");
    return rsfAuthObj.accessUid;
  };

  public setAccessUid = (accessUid: string): void => {
    let rsfAuth: any = {};

    if (this.hasAccessUid()) {
      const rsfAuthJson = sessionStorage.getItem("rsf-auth");
      rsfAuth = JSON.parse(rsfAuthJson || "{}");
    }

    rsfAuth.accessUid = accessUid;

    sessionStorage.setItem("rsf-auth", JSON.stringify(rsfAuth));
  };

  public clearRsfAuth = (): void => {
    sessionStorage.setItem("rsf-auth", null);
  };

  public async refreshToken(token: string): Promise<any> {
    try {
      const { tokens } = await fetchAuthSession({ forceRefresh: true });
      this.token = tokens?.accessToken?.toString() || "";
      this._jwt = tokens?.accessToken;
      this._idt = tokens?.idToken;
      StoreRegistry.getStore().dispatch(authActions.Actions.setToken(this.token));
    } catch (err) {
      console.error("Failed to Refresh: ", err);
    }
    return { user: StoreRegistry.get<ISession>("Session").currentUser };
  }

  public getCurrentUser(): { id: number; email: string; token: string } {
    if (this._user === null || this._user === "null") {
      return {} as any;
    }

    return this._user;
  }

  public setCurrentUser(id: number, email: string, token: string): void {
    this._user = {
      id: id,
      email: email,
      token: token,
    };
  }

  public async login(appState: any, password: string): Promise<IUser> {
    sessionStorage.setItem("LoginAppState", JSON.stringify(appState));
    try {
      await signInWithRedirect();
      await this.initialize();
    } catch (err) {
      if (err?.name === "UserAlreadyAuthenticatedException") {
        (StoreRegistry.get("$state") as any).go("occ_callback", {});
      } else {
        console.log(err);
      }
    }

    return this._user;
  }

  public async logout(refreshToken: string): Promise<void> {
    try {
      return await signOut();
    } catch (err) {
      console.error("Failed to logout: ", err);
    }
  }

  public async resetPassword(token: string, token_type: string, user: IUser): Promise<any> {
    return Promise.resolve();
  }

  public async initialize(): Promise<void> {
    try {
      const { accessToken, idToken } = (await fetchAuthSession()).tokens ?? {};
      this.token = accessToken.toString();
      this._jwt = accessToken;
      this._idt = idToken;
      await getCurrentUser();
      // const u = await StoreRegistry.getStore().dispatch(
      //   userActions.AsyncActions.getCurrentUserByEmail(this._idt.payload.email, this.token)
      // );
      // this.setCurrentUser(u.id, u.email, this.token);
      this.setupRefresher();
    } catch (err) {
      console.log(err);
    }
  }

  public handleRedirect = async () => {
    await this.initialize();

    let appState = sessionStorage.getItem("LoginAppState") || undefined;
    sessionStorage.removeItem("LoginAppState");

    if (appState) {
      appState = JSON.parse(appState);
    }

    return { appState: appState, user: this._idt.payload, token: this.token };
  };

  private async _loadUser() {
    StoreRegistry.getStore().dispatch(authActions.Actions.setToken(this.token));
    this._user = {
      ...this._idt.payload,
    } as any;
  }

  private async setupRefresher() {
    if (this._refresher !== undefined) {
      return;
    }

    this._refresher = setInterval(async () => {
      await this.refreshToken(this.token);
    }, 1000 * 60 * 25);
  }
}

export const cognitoAuth = new CongitoAuth();

// cognitoAuth.initialize();
