import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Row, Col } from "react-bootstrap";
import { getJobPresentations } from "../PresentationBuilder/presentationSelector";
import SpinnerComponent from "@rsfApp/app2/src/components/SpinnerComponent";
import * as presentationActions from "@rsfApp/app2/src/reducers/presentation.actions";
import * as jobActions from "@rsfApp/app2/src/reducers/job.actions";
import * as orgActions from "@rsfApp/app2/src/reducers/org.actions";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "@rsfApp/app2/src/helpers/Analytics";
import { OrgRecord } from "@rsfApp/app2/src/records/OrgRecord";
import { ImageRecord } from "@rsfApp/app2/src/records/Image";
import FlashAlert from "@rsfApp/app2/src/components/Common/FlashAlert";
import QuickNoteModal from "../QuickNote/QuickNoteContainer";
import { INoteResource } from "@rsfApp/app/src/Models/Note";
import Toolbar from "@rsfApp/app2/src/components/ScreenShare/Toolbar";
import { push } from "connected-react-router/immutable";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";

const mapStateToProps = (state: RootState, ownProps: PresentationViewerContainerProps) => {
  const job = state.getIn(["jobs", "byId", parseInt(ownProps.$stateParams.id)]);
  let org: OrgRecord;

  if (job && job.org_id > 0) {
    org = state.getIn(["orgs", "orgsById", job.org_id]);
  }

  return {
    jobPresentations: getJobPresentations(state, { jobId: parseInt(ownProps.$stateParams.id) }),
    job: job,
    org: org,
    presentation: state.getIn(["presentations", "byId", parseInt(ownProps.$stateParams.presentation_id)], null),
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: PresentationViewerContainerProps
) => {
  return {
    loadJob: (jobId: number) => {
      return dispatch(jobActions.AsyncActions.getJob(jobId));
    },
    loadOrg: (orgId: number) => {
      return dispatch(orgActions.AsyncActions.getOrg(orgId));
    },
    loadJobPresentations: (jobId: number) => {
      return dispatch(presentationActions.AsyncActions.listJobPresentations(jobId, ["slides"]));
    },
    loadOrgPresentations: (orgId: number) => {
      return dispatch(presentationActions.AsyncActions.listOrgPresentations(orgId, ["slides"]));
    },
    pushRoute: (route: string) => {
      return dispatch(push(route));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface PresentationViewerContainerProps {
  $analytics: angulartics.IAnalyticsService;
  $state: ng.ui.IStateService;
  $stateParams: { id: string; presentation_id?: string };
  Note: INoteResource;
  tracking?: TrackingProp;
}

const track: Track<TrackingData, PresentationViewerContainerProps> = _track;

export interface PresentationViewerContainerState {
  addingNote: boolean;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & PresentationViewerContainerProps;

@track(
  (props: Props) => {
    return {
      category: "Presentation Viewer",
      action: "Show",
      job: parseInt(props.$stateParams.id),
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  }
)
class PresentationViewerContainer extends React.Component<Props, PresentationViewerContainerState> {
  public iframe: any;
  constructor(props: Props) {
    super(props);

    Dispatch.analytics = props.$analytics;

    this.state = {
      addingNote: false,
    };

    window.addEventListener("message", this._eventListener);
    this.addNote = this.addNote.bind(this);
    this.closeNote = this.closeNote.bind(this);
    this.iframe = React.createRef();
  }

  public componentDidMount() {
    const { loadJob, loadOrg, loadJobPresentations, loadOrgPresentations, $stateParams } = this.props;

    loadJobPresentations(parseInt($stateParams.id));
    loadJob(parseInt($stateParams.id)).then((j) => {
      loadOrgPresentations(j.org_id).then(() => {
        if (this.iframe.current) {
          this.iframe.current.focus();
        }
      });
      loadOrg(j.org_id);
    });
    if (this.iframe.current) {
      this.iframe.current.focus();
    }
  }

  public componentWillUnmount() {
    window.removeEventListener("message", this._eventListener);
  }

  public render() {
    const { jobPresentations, Note, $stateParams, presentation } = this.props;
    const { addingNote } = this.state;

    if (!jobPresentations || ($stateParams.presentation_id && !presentation)) {
      return <SpinnerComponent localProperty={true} />;
    }

    let presentation_url = `${jobPresentations.first({ url: "" }).url}?dynamic=true`;
    if (presentation) {
      presentation_url = `${presentation.get("url")}`;
    }

    return (
      <div className="presentation-viewer">
        <FlashAlert />
        <div className="presentation-job-header">
          <Row>
            <Col sm={6} className="header-logo">
              {this.logo()}
            </Col>
            <Col sm={6}>
              <div className="item">
                <a href="#" onClick={this.close}>
                  <i className="fa fa-times-circle" />
                  <div>Close</div>
                </a>
              </div>
              <div className="screen-share-toolbar-react">
                <Toolbar />
              </div>
              <div className="item">
                <a href="#" onClick={this.addNote}>
                  <i className="fa fa-edit" />
                  <div>Notes</div>
                </a>
              </div>
            </Col>
          </Row>
        </div>
        <iframe ref={this.iframe} className="presentation-full" src={presentation_url} />
        <QuickNoteModal open={addingNote} Note={Note} jobId={parseInt($stateParams.id)} close={this.closeNote} />
      </div>
    );
  }

  protected close = () => {
    const { $stateParams, pushRoute } = this.props;
    if ($stateParams.presentation_id) {
      pushRoute(`/jobs/${$stateParams.id}/presentations`);
      StoreRegistry.get("$rootScope").$digest();
    } else {
      pushRoute(`/jobs/${$stateParams.id}/presentations/builder`);
      StoreRegistry.get("$rootScope").$digest();
    }
  };

  protected logo = () => {
    const { org } = this.props;
    if (org) {
      const image = org.images.first(null);
      if (image !== null) {
        return <img src={(image as ImageRecord).file.small.url} />;
      }
    }

    return <span>No Logo</span>;
  };

  @track({ action: "Close Quick Note" })
  protected closeNote() {
    this.setState({ addingNote: false });
  }

  @track({ action: "Open Quick Note" })
  protected addNote() {
    this.setState({ addingNote: true });
  }

  protected _eventListener = (event) => {
    const { tracking, jobPresentations } = this.props;
    let data: any;

    if (_.isString(event.data)) {
      try {
        data = JSON.parse(event.data);
      } catch (e) {
        data = {};
      }
    } else {
      data = event.data;
    }

    if (data.namespace === "reveal" && data.eventName === "slidechanged") {
      tracking.trackEvent({
        action: "Slide Changed",
        presentation: jobPresentations.first({ id: "" }).id,
        page: data.state.indexh,
        level: data.state.indexv,
      });
    }
  };
}

export default connector(PresentationViewerContainer);
