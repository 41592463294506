import { BaseTabCtrl } from "@rsfApp/app/src/Jobs/tabs/BaseTabCtrl";
import { IJobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";
import * as angulartics from "angulartics";
import { IDirtyWatcher } from "@rsfApp/app/src/Common/DirtyWatcher";
import { IFileQueueInit } from "@rsfApp/app/src/Common/FileQueueFactory";
import { IFileQueueFactory } from "@rsfApp/app/src/Common/FileQueueFactory";
import { IEstimate } from "@rsfApp/app/src/Models/Estimate";
import { EstimatorService } from "@rsfApp/app/src/Estimator/EstimatorService";
import { IConfirmDialog } from "@rsfApp/app/src/Common/ConfirmDialogService";

export class InspectionTabCtrl extends BaseTabCtrl {
  public fileQueue: IFileQueueFactory;

  public static $inject = [
    "JobFetcher",
    "DirtyWatcher",
    "FileQueueFactory",
    "$scope",
    "$stateParams",
    "$window",
    "$uibModal",
    "EstimatorService",
    "ConfirmDialog",
    "$analytics",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public DirtyWatcher: IDirtyWatcher,
    protected FileQueueFactory: IFileQueueInit,
    public $scope: ng.IScope,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    public $uibModal: ng.ui.bootstrap.IModalService,
    public EstimatorService: EstimatorService,
    public ConfirmDialog: IConfirmDialog,
    protected $analytics: angulartics.IAnalyticsService
  ) {
    super(JobFetcher, $scope, $analytics, $window, $stateParams["id"]);

    this.fileQueue = FileQueueFactory.getInstance();

    this.emailPdf = this.emailPdf.bind(this);
    this.downloadPdf = this.downloadPdf.bind(this);
  }

  public downloadPdf(estimate: IEstimate) {
    this.trackEvent("download inspection", {
      category: "Inspections",
      estimate: estimate.id,
    });

    let include_all_items;
    this.ConfirmDialog.confirm("Include All Inspection Items?")
      .then(() => {
        include_all_items = true;
      })
      .catch(() => {
        include_all_items = false;
      })
      .finally(() => {
        this.spinnerPromise = this.EstimatorService.savePdf(estimate, "inspection", {
          inspection_include_all_items: include_all_items,
        });
      });
  }

  public emailPdf(estimate: IEstimate) {
    this.trackEvent("email inspection", {
      category: "Inspections",
      estimate: estimate.id,
    });

    const modalInstance: ng.ui.bootstrap.IModalServiceInstance = this.$uibModal.open(<ng.ui.bootstrap.IModalSettings>{
      animation: true,
      ariaLabelledBy: "modal-title",
      ariaDescribedBy: "modal-body",
      templateUrl: "src/Jobs/tabs/email_modal.html",
      controller: "EmailModal",
      backdrop: "static",
      controllerAs: "$ctrl",
      size: "md",
      resolve: {
        job_email: { email: this.job.email },
        options: {
          inspection_include_all_items: false,
        },
      },
    });

    modalInstance.result
      .then((returnObject: any) => {
        this.spinnerPromise = this.EstimatorService.emailPdf(estimate.id, "inspection", returnObject);
      })
      .catch(() => {
        this.trackEvent("email inspection canceled", {
          category: "Inspections",
          estimate: estimate.id,
        });
      });
  }
}
