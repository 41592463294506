import * as React from "react";
import { useSelector } from "react-redux";
import { Nullable } from "@rsfApp/app2/src/records";
import { EstimateLineItemRecord } from "@rsfApp/app2/src/records/EstimateLineItem";
import { lineItemsEstimate } from "@rsfApp/app2/src/selectors/estimate.selectors";
import { InspectionSlide } from "./InspectionSlide";

export interface IInspectionSlidesProps {
  inspectionId: Nullable<number>;
}

export const InspectionSlides: React.FC<IInspectionSlidesProps> = ({ inspectionId }) => {
  if (!inspectionId) {
    return <span></span>;
  }
  const lineItems = useSelector((state: any) => lineItemsEstimate(state, { estimateId: inspectionId }));

  return (
    <React.Fragment>
      {lineItems
        .filter((l: EstimateLineItemRecord) => {
          return l.visibility === "b" || l.visibility === "ab";
        })
        .map((li: EstimateLineItemRecord, idx: number) => {
          return <InspectionSlide key={idx} lineItem={li} />;
        })}
    </React.Fragment>
  );
};
