import * as React from "react";

export interface IRadioCircleProps {
  checked: boolean;
  name: string;
  id: string;
  disabled?: boolean;
  column?: boolean;
  color?: string;
  onChange?: (value: boolean) => void;
}

export interface ICheckCircleState {
  checked: boolean;
}

export class RadioCircle extends React.Component<IRadioCircleProps, ICheckCircleState> {
  constructor(props) {
    super(props);

    this.state = {
      checked: props.checked,
    };
  }

  public componentDidUpdate() {
    if (this.props.checked !== this.state.checked) {
      this.setState({
        checked: this.props.checked,
      });
    }
  }

  public _handleChange = () => {
    if (this.props.onChange) {
      this.props.onChange(!this.state.checked);
    }
  };

  public render() {
    const { disabled, color, name, id, column } = this.props;
    const { checked } = this.state;
    let className = "check";
    let inputClassName = "check-circle";
    if (column) {
      className = `${className} col-sm-1 center-checkbox`;
    }
    if (color) {
      inputClassName = `${inputClassName} ${color}`;
    }

    return (
      <div className={className}>
        <label>
          <input
            type="radio"
            className={inputClassName}
            id={id}
            name={name}
            checked={checked}
            disabled={disabled}
            onChange={this._handleChange}
          />
          <span />
        </label>
      </div>
    );
  }
}
