import * as React from "react";
import Spinner from "@rsfApp/app2/src/components/SpinnerComponent";
import * as config from "react-global-configuration";

interface LeadsAuthComponentProps {}

export const LeadsAuthComponent: React.FunctionComponent<LeadsAuthComponentProps> = () => {
  React.useEffect(() => {
    window.location = config.get("LEADS_URL");
  }, []);

  return <Spinner />;
};
