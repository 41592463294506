import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Card, Accordion, Row, Col, Tooltip, Button } from "react-bootstrap";
import { List } from "immutable";
import SlideComponent from "./SlideComponent";
import { Nullable } from "@rsfApp/app2/src/records";
import { PresentationRecord } from "@rsfApp/app2/src/records/Presentation";
import * as presentationActions from "@rsfApp/app2/src/reducers/presentation.actions";
import * as builderService from "./PresentationBuilder.service";
import { SlideRecord } from "@rsfApp/app2/src/records/Slide";
import { getSortOrder } from "./PresentationBuilder.service";

const mapStateToProps = (state: RootState, ownProps: IPresentationComponentProps) => {
  return {};
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IPresentationComponentProps
) => {
  return {
    updatePresentation: (presentation: PresentationRecord) => {
      dispatch(presentationActions.Actions.receivePresentation(presentation));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IPresentationComponentProps {
  presentation: any;
  dynamicPresentation: PresentationRecord;
  inspectionId: number;
  orgPresentations: List<PresentationRecord>;
  selectedEventKey: Nullable<string>;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPresentationComponentProps;

class PresentationComponent extends React.Component<Props> {
  public sliderRef: React.RefObject<HTMLDivElement>;
  protected _tooltip = (<Tooltip id={this.props.presentation.id}>Present Source Presentation</Tooltip>);

  constructor(props) {
    super(props);

    this.sliderRef = React.createRef();
    this.addSlides = this.addSlides.bind(this);
    this.removeSlides = this.removeSlides.bind(this);
  }

  public addSlides(e) {
    e.stopPropagation();
    const { presentation, dynamicPresentation, updatePresentation, orgPresentations } = this.props;
    updatePresentation(
      builderService.addSlides(
        dynamicPresentation,
        presentation,
        orgPresentations,
        getSortOrder(presentation, orgPresentations)
      )
    );
  }

  public removeSlides(e) {
    e.stopPropagation();
    const { presentation, dynamicPresentation, updatePresentation } = this.props;
    updatePresentation(builderService.removeSlides(dynamicPresentation, presentation));
  }

  public render() {
    const { dynamicPresentation, presentation, selectedEventKey } = this.props;
    return (
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey={presentation.id.toString()}>
          <Row>
            <Col sm={12}>
              {presentation.name}&nbsp;&nbsp;
              <i className="rsf-plus-link" onClick={this.addSlides} title="Add All Slides" />
              &nbsp;&nbsp;
              <i className="rsf-minus-link" onClick={this.removeSlides} title="Remove All Slides" />
              {selectedEventKey !== presentation.id.toString() && (
                <a
                  href={presentation.url}
                  title="View Original Presentation"
                  onClick={(e) => e.stopPropagation()}
                  target="_blank"></a>
              )}
              <i
                className={`fa fa-angle-${selectedEventKey === presentation.id.toString() ? "up" : "down"} pull-right`}
              />
            </Col>
          </Row>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={presentation.id.toString()}>
          <Card.Body>
            <Row>
              {/*
              // @ts-ignore */}
              <Col sm={12} className="slide-container" ref={this.sliderRef}>
                {presentation.slides.map((s, idx) => {
                  return (
                    <SlideComponent
                      key={idx}
                      slide={s}
                      selected={builderService.slideSelected(dynamicPresentation, s.id)}
                      toggle={(state) => {
                        if (state) {
                          this.addSlide(s);
                        } else {
                          this.removeSlide(s.id);
                        }
                      }}
                      showToggle={true}
                    />
                  );
                })}
              </Col>
              <div
                className="btn btn-borderless horizontal-scroller-right"
                onClick={() => {
                  this.sliderRef.current.scrollLeft = this.sliderRef.current.scrollLeft + 160;
                }}>
                <i className="fa fa-arrow-right"></i>
              </div>
              <div
                className="btn btn-borderless horizontal-scroller-left"
                onClick={() => {
                  this.sliderRef.current.scrollLeft = this.sliderRef.current.scrollLeft - 160;
                }}>
                <i className="fa fa-arrow-left"></i>
              </div>
            </Row>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    );
  }

  protected removeSlide = (slideId: number) => {
    const { dynamicPresentation, updatePresentation } = this.props;

    updatePresentation(builderService.removeSlide(dynamicPresentation, slideId));
  };

  protected addSlide = (slide: SlideRecord) => {
    const { dynamicPresentation, updatePresentation, orgPresentations } = this.props;

    updatePresentation(
      builderService.addSlide(
        dynamicPresentation,
        slide,
        orgPresentations,
        getSortOrder(dynamicPresentation, orgPresentations)
      )
    );
  };
}

export default connector(PresentationComponent);
