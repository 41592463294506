import * as React from "react";
import { List } from "immutable";
import { Accordion, Row, Col } from "react-bootstrap";
import PresentationComponent from "./PresentationComponent";
import { Nullable } from "@rsfApp/app2/src/records";
import { PresentationRecord } from "@rsfApp/app2/src/records/Presentation";

export interface IPresentationOverviewProps {
  dynamicPresentation: PresentationRecord;
  presentations: List<PresentationRecord>;
  inspectionId: number;
}

export interface IPresentationOverviewState {
  selectedEventKey: Nullable<string>;
}

export default class PresentationOverview extends React.Component<
  IPresentationOverviewProps,
  IPresentationOverviewState
> {
  constructor(props) {
    super(props);
    this.state = {
      selectedEventKey: null,
    };
  }

  public render() {
    const { presentations, dynamicPresentation, inspectionId } = this.props;
    const { selectedEventKey } = this.state;

    return (
      <Row>
        <Col sm={12}>
          <Accordion
            className="bucket-accordion"
            activeKey={selectedEventKey}
            onSelect={(selectedEventKey) => this.setState({ selectedEventKey })}>
            {presentations.map((a, idx) => {
              return (
                <PresentationComponent
                  key={a.id}
                  inspectionId={inspectionId}
                  orgPresentations={presentations}
                  dynamicPresentation={dynamicPresentation}
                  presentation={a}
                  selectedEventKey={selectedEventKey}
                />
              );
            })}
          </Accordion>
        </Col>
      </Row>
    );
  }
}
