import * as ng from "angular";
import { ISession } from "@rsfApp/app/src/Common/SessionService";
import { IUser } from "@rsfApp/app/src/Models/User";
import { INavDisplayArgs } from "@rsfApp/app/src/Jobs/JobShowCtrl";
import { JobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";
import { IJob } from "@rsfApp/app/src/Models/Job";
import * as angulartics from "angulartics";
import { subscriber } from "@rsfApp/app2/src/helpers/Subscribe";
import * as commonActions from "@rsfApp/app2/src/reducers/components/common.actions";
import { RootStoreType } from "@rsfApp/app2/src/store";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { GafJobMenuOrderService } from "app/src/JobMenu/GafJobMenuOrderService";
import { ISideBarMenu } from "@rsfApp/app/src/JobMenu/JobMenuOrderService";
import { IOrg } from "@rsfApp/app/src/Models/Org";
import { IBaseConfig } from "@rsfApp/app/src/Common/IBaseConfig";
import * as discountActions from "app2/src/reducers/discount.actions";
import * as paymentTermTemplateActions from "app2/src/reducers/org/paymentTermTemplate.actions";
import * as financeOptionActions from "app2/src/reducers/org/financeOption.actions";
import * as orgActions from "app2/src/reducers/org.actions";
import * as validationActions from "app2/src/reducers/validation.actions";
import { IOrgData } from "app2/src/records/OrgRecord";
import * as jobActions from "@rsfApp/app2/src/reducers/job.actions";

export interface IHeaderShowArgs {
  show: boolean;
}

export interface IHeaderSearchArgs {
  searchString?: string;
}

export class SidebarCtrl {
  public job: IJob;
  public user: IUser;
  public navDisplay = true;
  public isNavCollapsed = true;
  public adUrl: string;
  public testimonial: any;
  public hidden = false;
  public sendInvite = false;
  public store: RootStoreType;
  public navImgSrc = {
    home: "",
    calendar: "",
    setup: "",
    salesProcess: "",
  };
  public org: IOrg;
  public sidebar: ISideBarMenu;

  public _cleanupListeners: Function[] = [];
  private _sendInviteSubscribe: () => void;

  public static $inject = [
    "Session",
    "JobFetcher",
    "$state",
    "$scope",
    "$rootScope",
    "$location",
    "$analytics",
    "GafJobMenuOrderService",
    "BaseConfig",
  ];
  constructor(
    private Session: ISession,
    public JobFetcher: JobFetcherService,
    public $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    public $rootScope: ng.IRootScopeService,
    private $location: ng.ILocationService,
    private $analytics: angulartics.IAnalyticsService,
    public GafJobMenuOrderService: GafJobMenuOrderService,
    public BaseConfig: IBaseConfig
  ) {
    this.user = Session.currentUser;

    this._cleanupListeners.push(
      $rootScope.$on("nav:display", (event: ng.IAngularEvent, args: INavDisplayArgs) => {
        this.navDisplay = args.navDisplay;
      })
    );

    this.store = StoreRegistry.getStore();
    this._sendInviteSubscribe = subscriber.subscribe<boolean>(
      "components.common.sendInviteModal",
      (sendInviteModal: boolean) => {
        this.sendInvite = sendInviteModal;
        _.defer(() => {
          this.$scope.$digest();
        });
      }
    );

    $scope.$on("$destroy", () => {
      StoreRegistry.getStore().dispatch(jobActions.Actions.setSelectedEstimateId(null));
      _.each(this._cleanupListeners, (func: () => void) => {
        if (_.isFunction(func)) {
          func();
        }
      });
    });

    if (this.isJobPage()) {
      if (this.$state.params["id"] && this.$state.params["id"] !== "new") {
        this._loadJob();
      } else {
        Session.currentUser.$promise.then(() => {
          this.org = JobFetcher.getOrg(Session.currentUser.org_id);
          this.org.$promise.then(() => {
            this.sidebar = this.GafJobMenuOrderService.buildTabs(this.org, this.job);
          });
        });
        const watcher = this.$scope.$watch(
          () => {
            return $location.path();
          },
          () => {
            if ($location.path().indexOf("new") >= 0) {
              return;
            }
            watcher();
            this._loadJob();
          }
        );
      }
    }
  }

  public isJobPage(): boolean {
    return new RegExp(/^\/jobs\/+/).test(this.$location.path());
  }

  private _loadJob() {
    if (this.$state.params["id"] === "new") {
      return;
    }
    this.job = this.JobFetcher.get(this.$state.params["id"]);

    this.job.$promise.then(() => {
      this.org = this.job.org;
      // Initialize Redux for each job
      this.store.dispatch(orgActions.Actions.setOrg((this.org as any) as IOrgData));
      this.store.dispatch(discountActions.AsyncActions.listDiscounts(this.job.org_id));
      this.store.dispatch(paymentTermTemplateActions.AsyncActions.listPaymentTermTemplates(this.job.org_id));
      this.store.dispatch(financeOptionActions.AsyncActions.listFinanceOptions(this.job.org_id));
      this.store.dispatch(validationActions.AsyncActions.listValidations(this.job.org_id));

      this.sidebar = this.GafJobMenuOrderService.buildTabs(this.org, this.job);
    });
  }
}
