import { JobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";

export class GafJobFetcherService extends JobFetcherService {
  public includes = [
    "address",
    "assignments",
    "notes",
    "state_log",
    "job_states",
    "appointments",
    "org",
    "settings",
    "lead_sources",
    "job_types",
    "presentation_count",
    "visualizations",
    "preferences",
    "reports",
    "lead",
    "weather_property",
    "estimate_comparison",
  ];
}
