import "./preload";
import "@rsfApp/app/src/Vendor";
import "app/src/templates.js"; // GAF Templates prefixed with "gaf/".  e.g. "gaf/src/Header/header.html"
import "@rsfApp/app/src/templates.js"; // RSF App templates, standard paths. e.g. "src/Header/header.html"

import "app2/src/Ioc";
import { StoreRegistry, dispatch } from "@rsfApp/app2/src/storeRegistry";
import * as angular from "angular";
import * as ng from "angular";
import * as moment from "moment";
import { IBaseConfig } from "@rsfApp/app/src/Common/IBaseConfig";
import { TransitionService, Transition } from "@uirouter/core";
import { GafHeaderCtrl } from "app/src/Header/GafHeaderCtrl";
import { Session, ISession } from "@rsfApp/app/src/Common/SessionService";
import { RsfPermissions } from "@rsfApp/app/src/Common/PermissionsDirective";
import { RsfConfirmationDialog } from "@rsfApp/app/src/Common/ConfirmationDialogDirective";
import { DecimalPlaces } from "@rsfApp/app/src/Common/DecimalPlacesDirective";
import { JobAnalytics } from "@rsfApp/app/src/Common/JobAnalyticsDirective";
import { us_states, countries, us_states_abbreviation, countries_abbreviation } from "app/src/Common/StatesConstant";
import { GafJobRoutes } from "app/src/Jobs/GafJobRoutes";
import { GafOrgRoutes } from "app/src/Orgs/GafOrgRoutes";
import { ProductColorDropdown } from "@rsfApp/app/src/Estimator/ProductColorDropdown";
import { HumanizeFilter } from "@rsfApp/app/src/Common/HumanizeFilter";
import { CalendarRoutes } from "@rsfApp/app/src/Calendar/CalendarRoutes";
import { GafEstimatorRoutes } from "app/src/Estimator/GafEstimatorRoutes";
import { ScreenShareRoutes } from "@rsfApp/app/src/ScreenShare/ScreenShareRoutes";
import { GafProfileRoutes } from "app/src/Profile/GafProfileRoutes";
import { AuthHttpInterceptor } from "@rsfApp/app/src/Common/AuthHttpInterceptor";
import { FakeFormAction } from "@rsfApp/app/src/Common/FakeFormAction";
import { SignatureRoutes } from "@rsfApp/app/src/Signature/SignatureRoutes";
import { SpinnerComponent } from "@rsfApp/app/src/Common/SpinnerComponent";
import { EmbedSrc } from "@rsfApp/app/src/Common/EmbedSrcDirective";
import { FlashService } from "@rsfApp/app/src/Common/FlashService";
import { ConfirmDialogService } from "@rsfApp/app/src/Common/ConfirmDialogService";
import { GafPaymentInfoRoutes } from "app/src/PaymentInfo/GafPaymentInfoRoutes";
import { ModelRoutes } from "@rsfApp/app/src/Models/ModelRoutes";
import { GafLoginRoutes } from "app/src/Auth/GafLoginRoutes";
import { VisualizationRoutes } from "@rsfApp/app/src/Visualization/VisualizationRoutes";
import { UnitConversionService } from "@rsfApp/app/src/Common/UnitConversionService";
import { PrettyNameService } from "app/src/Common/PrettyNameService";
import { ToolInfoService } from "@rsfApp/app/src/Common/ToolInfoService";
import { HandlebarsHelperService } from "@rsfApp/app/src/Patterns/HandlebarsHelperService";
import { EstimatorTourService } from "@rsfApp/app/src/Common/Tours/EstimatorTourService";
import { JobInfoTourService } from "@rsfApp/app/src/Common/Tours/JobInfoTourService";
import { JobShowTourService } from "@rsfApp/app/src/Common/Tours/JobShowTourService";
import { DirtyWatcher } from "@rsfApp/app/src/Common/DirtyWatcher";
import EventingService from "@rsfApp/app/src/Common/EventingFactory";
import FileQueueInit from "@rsfApp/app/src/Common/FileQueueFactory";
import MeasurementImporterFactory from "@rsfApp/app/src/Common/MeasurementImporterFactory";
import * as Humanize from "humanize-plus";
import { IRepository, Repository } from "@rsfApp/app/src/Common/Repository";
import { SetFocus } from "@rsfApp/app/src/Common/SetFocus";
import { RsfTab } from "@rsfApp/app/src/Common/RsfTab";
import { ScrollIf } from "@rsfApp/app/src/Common/ScrollIf";
import { valid_uom, valid_kind } from "@rsfApp/app/src/Common/Constants";
import { StartFromFilter } from "@rsfApp/app/src/Common/StartFromFilter";
import { BillingRoutes } from "@rsfApp/app/src/Billing/BillingRoutes";
import { GafJobMenuRoutes } from "app/src/JobMenu/GafJobMenuRoutes";
import { ReportRoutes } from "@rsfApp/app/src/Reports/ReportRoutes";
import { DrawingComponent } from "@rsfApp/app/src/Drawing/DrawingComponent";
import { ToolDisplayComponent } from "@rsfApp/app/src/Common/Components/ToolDisplayComponent";
import { RefreshButtonComponent } from "@rsfApp/app/src/Common/Components/RefreshButtonComponent";
import { TosHttpInterceptor } from "@rsfApp/app/src/Common/TosHttpInterceptor";
import { GmapsAutocompleteComponent } from "@rsfApp/app/src/GoogleMapsAutocomplete/GmapsAutocompleteComponent";
import { LeafletMapComponent } from "@rsfApp/app/src/LeafletMap/LeafletMapComponent";
import { RsfMapComponent } from "@rsfApp/app/src/RsfMap/RsfMapComponent";
import { OrgActivationHttpInterceptor } from "@rsfApp/app/src/Common/OrgActivationHttpInterceptor";
import { SignedDocRoutes } from "@rsfApp/app/src/SignedDocuments/SignedDocRoutes";
import { RsfRootScope } from "@rsfApp/app/src/Common/RsfRootScope";
import { ForbiddenHttpInterceptor } from "@rsfApp/app/src/Common/ForbiddenHttpInterceptor";
import * as ngResource from "angular-resource";
import * as ngMap from "ngmap";
import uiTour from "angular-ui-tour/dist/angular-ui-tour";
import { OrgAclType } from "@rsfApp/app/src/Models/Org";
import { LearnMoreAds } from "@rsfApp/app2/src/components/LearnMoreAds";
import { react2angular } from "react2angular";
import { AdService } from "@rsfApp/app/src/Common/AdService";
import PdfDisplay from "@rsfApp/app2/src/components/Pdf/PdfDisplay";
import SaveablePdfDisplay from "@rsfApp/app2/src/components/Pdf/SaveablePdfDisplay";
import FlashAlert from "@rsfApp/app2/src/components/Common/FlashAlert";
import ScreenShare from "@rsfApp/app2/src/components/ScreenShare/ScreenShare";
import Toolbar from "@rsfApp/app2/src/components/ScreenShare/Toolbar";
import Notifier from "@rsfApp/app2/src/components/Common/Notifier";
import SalesProcess from "../app2/src/Components/SalesProcess";
import { ReactSelectWrapper } from "@rsfApp/app2/src/components/Common/ReactSelectWrapper";
import RsfSref from "@rsfApp/app/src/Routing/RsfSrefDirective";
import JobTabs from "app2/src/Components/JobTabs";
import InviteModal from "@rsfApp/app2/src/components/Common/InviteModal";
import { Router as ReactRouter } from "@rsfApp/app2/src/helpers/Router";
import { push } from "connected-react-router/immutable";
import { FinanceOptionRoutes } from "@rsfApp/app/src/FinanceOption/FinanceOptionRoutes";
import NgDocumentLink from "@rsfApp/app2/src/components/Document/NgDocumentLink";
import PageTitleUpdater from "@rsfApp/app2/src/components/Common/PageTitleUpdater";
import OrgTabs from "@rsfApp/app2/src/components/OrgTabs";
import { setupServiceWorker, checkForUpdate } from "@rsfApp/app2/src/services/serviceWorker.service";

const projectModules = [
  ModelRoutes,
  GafJobRoutes,
  GafJobMenuRoutes,
  GafEstimatorRoutes,
  CalendarRoutes,
  ScreenShareRoutes,
  GafProfileRoutes,
  SignatureRoutes,
  GafPaymentInfoRoutes,
  VisualizationRoutes,
  ReportRoutes,
  SignedDocRoutes,
  FinanceOptionRoutes,
];

const commonModules = [GafOrgRoutes, BillingRoutes, GafLoginRoutes];

const app = angular
  .module("RSF", [
    "GAF.templates",
    "RSF.templates",
    "RSF.globals",
    ngResource,
    "ngStorage",
    "ngSanitize",
    "ui.router",
    "ui.bootstrap",
    "ui.sortable",
    "kendo.directives",
    "ngFileUpload",
    "ngMessages",
    ngMap,
    "angulartics",
    "angulartics.rsf.analytics",
    "stripe.checkout",
    "infinite-scroll",
    "focus-if",
    "cfp.hotkeys",
    uiTour,
    "nvd3",
  ])
  .config(config)
  .constant("COUNTRIES", countries)
  .constant("US_STATES", us_states)
  .run(run);

config.$inject = <ReadonlyArray<string>>[
  "$provide",
  "$locationProvider",
  "$stateProvider",
  "$urlRouterProvider",
  "$httpProvider",
  "$analyticsProvider",
  "StripeCheckoutProvider",
  "$qProvider",
  "BaseConfig",
  "$uiRouterProvider",
];

function config(
  $provide: ng.auto.IProvideService,
  $locationProvider: ng.ILocationProvider,
  $stateProvider: ng.ui.IStateProvider,
  $urlRouterProvider: ng.ui.IUrlRouterProvider,
  $httpProvider: ng.IHttpProvider,
  $analyticsProvider: angulartics.IAnalyticsServiceProvider,
  StripeCheckoutProvider: ng.stripe.IStripeCheckoutProvider,
  $qProvider: ng.IQProvider,
  BaseConfig: IBaseConfig,
  $uiRouterProvider: any
) {
  ($analyticsProvider.settings as any).rsf_analytics_key = BaseConfig.RSF_ANALYTICS_KEY;
  ($analyticsProvider.settings as any).rsf_analytics_url = BaseConfig.RSF_ANALYTICS_URL;
  $analyticsProvider.trackExceptions(true);
  $qProvider.errorOnUnhandledRejections(false);

  $uiRouterProvider.stateService.defaultErrorHandler(_.noop());

  //html5 removes the need for # in URL
  $locationProvider
    .html5Mode({
      enabled: true,
      requireBase: false,
    })
    .hashPrefix("!");

  const resolveAuth = {
    auth: [
      "Session",
      "$q",
      (Session, $q) => {
        if (!Session.hasToken()) {
          return $q.reject({ reason: "unauthorized" });
        }

        return Session.currentUser.$promise;
      },
    ],
  };

  const resolveAuthRequireAnyModule = {
    auth: [
      "Session",
      "$q",
      (Session, $q: ng.IQService) => {
        return (resolveAuth.auth[2] as any)(Session, $q).then((user) => {
          return $q
            .all([
              Session.can("estimate_module", "org_modules", null),
              Session.can("leads_module", "org_modules", null),
            ])
            .then((values: boolean[]) => {
              if (values.some((a) => a)) {
                return user;
              }

              return $q.reject({ reason: "unauthorized module" });
            });
        });
      },
    ],
  };

  const resolveAuthRequireProjectModule = {
    auth: [
      "Session",
      "$q",
      (Session, $q: ng.IQService) => {
        return (resolveAuth.auth[2] as any)(Session, $q).then((user) => {
          return Session.can("estimate_module", "org_modules", null).then((value) => {
            if (value) {
              return user;
            }

            return $q.reject({ reason: "unauthorized module" });
          });
        });
      },
    ],
  };

  $provide.constant("resolveAuth", resolveAuthRequireAnyModule);
  $provide.constant("resolveAuthRequireProjectModule", resolveAuthRequireProjectModule);
  $provide.constant("resolveAuthRequireAnyModule", resolveAuthRequireAnyModule);

  if (process.env.NODE_ENV !== "development" && "serviceWorker" in navigator) {
    setupServiceWorker(navigator, window, sessionStorage);
  }

  $urlRouterProvider.otherwise(BaseConfig.DEFAULT_ROUTE_LINK);
  $urlRouterProvider.when("/jobs/{id:[0-9]{1,10}|new}", "/jobs/:id/info");
  $urlRouterProvider.when("/orgs/{id:[0-9]{1,10}|new}", "/orgs/:id/info");

  //angular-ui-router for multiple view
  $stateProvider.state("root", <ng.ui.IState>{
    abstract: true,
    views: {
      header: {
        templateUrl: "gaf/src/Header/header.html",
        controller: "GafHeaderCtrl",
        controllerAs: "ctrl",
      },
    },
  });

  $httpProvider.interceptors.push(ForbiddenHttpInterceptor.factory);

  if (BaseConfig.ENFORCE_TOS === true) {
    $httpProvider.interceptors.push(TosHttpInterceptor.factory);
  }
  $httpProvider.interceptors.push(OrgActivationHttpInterceptor.factory);
  $httpProvider.interceptors.push(AuthHttpInterceptor.factory);

  _.each(commonModules, (routes) => {
    routes.configure($stateProvider, resolveAuthRequireAnyModule);
  });
  _.each(projectModules, (routes) => {
    routes.configure($stateProvider, resolveAuthRequireProjectModule);
  });

  StripeCheckoutProvider.defaults(<ng.stripe.IStripeCheckoutConfig>{
    key: BaseConfig.STRIPE_KEY,
  });
}

run.$inject = <ReadonlyArray<string>>[
  "$rootScope",
  "$state",
  "$window",
  "$location",
  "Repository",
  "Session",
  "$transitions",
  "BaseConfig",
  "$uibModalStack",
];
function run(
  $rootScope: RsfRootScope,
  $state: ng.ui.IStateService,
  $window: ng.IWindowService,
  $location: ng.ILocationService,
  Repository: IRepository,
  Session: ISession,
  $transitions: TransitionService,
  BaseConfig: IBaseConfig,
  $uibModalStack: ng.ui.bootstrap.IModalStackService
) {
  ReactRouter.setup($state);
  StoreRegistry.set("$state", $state);
  StoreRegistry.set("$location", $location);
  StoreRegistry.set("$rootScope", $rootScope);
  StoreRegistry.set("Pretty", new PrettyNameService());
  StoreRegistry.set("Session", Session);
  $transitions.onFinish({}, ({ router }) => {
    Session.stripAuthUser();
  });

  $transitions.onStart({}, (transition) => {
    $rootScope.$broadcast("RSF_StateChange", { transition: transition });
    const fromUrl = $location.url();
    transition.onSuccess(
      {},
      async () => {
        if (fromUrl !== $location.url()) {
          dispatch(push($location.url()));
        }

        if (process.env.NODE_ENV !== "development" && "serviceWorker" in navigator) {
          const regs = await navigator.serviceWorker.getRegistrations();
          regs.forEach((reg) => {
            if (reg.waiting) {
              reg.waiting?.postMessage({ type: "SKIP_WAITING" });
              checkForUpdate(reg.waiting, navigator, window, localStorage);
            } else {
              reg.update();
            }
          });
        }
      },
      { priority: -1 }
    );
  });

  $transitions.onError({}, function (trans: Transition) {
    if (trans.error().detail && trans.error().detail.reason === "unauthorized") {
      $rootScope.$broadcast("auth:logout");
      $rootScope.savedState = trans.$to().name;
      $rootScope.savedParams = trans.$to().params;
      $state.go("root.login", { routeTo: trans.to(), routeParams: trans.params() });
    }
    if (trans.error().detail && trans.error().detail.reason === "unauthorized module") {
      Session.can("leads_module", "org_modules", null).then((value) => {
        if (value) {
          //@ts-ignore
          ($window.location as any) = BaseConfig.LEADS_URL;
        } else {
          $state.go("root.alert");
        }
      });
    }
  });

  window.addEventListener("beforeunload", function (e) {
    const confirmation: any = {};
    const event = $rootScope.$broadcast("onBeforeUnload", confirmation);
    if (event.defaultPrevented) {
      e.returnValue = confirmation["message"];
      return confirmation["message"];
    }
  });

  $window.onunload = function () {
    $rootScope.$broadcast("onUnload");
  };

  $rootScope.underscore = _;
  $rootScope.Humanize = Humanize;
  $rootScope.OrgAclType = OrgAclType;

  $rootScope.moment = moment;
  $rootScope.setAppName = () => {
    return BaseConfig.APP_NAME;
  };
  $rootScope.setCompanyName = () => {
    return BaseConfig.COMPANY_NAME;
  };
  $rootScope.setAppLogoUrl = () => {
    return BaseConfig.APP_LOGO_URL;
  };
  $rootScope.setAppIconUrl = () => {
    return BaseConfig.APP_ICON_URL;
  };
  $rootScope.setFaviconUrl = () => {
    return BaseConfig.FAVICON_FILE;
  };
  $rootScope.$on("$locationChangeStart", () => $uibModalStack.dismissAll());
}

_.each(commonModules.concat(projectModules), (routes) => {
  if (routes.load) {
    routes.load(app);
  }
});

app.controller("GafHeaderCtrl", GafHeaderCtrl);
app.service("Session", Session);
app.service("Flash", FlashService);
app.service("ConfirmDialog", ConfirmDialogService);
app.service("Conversion", UnitConversionService);
app.service("Pretty", PrettyNameService);
app.service("ToolInfo", ToolInfoService);
app.service("AdService", AdService);
app.service("EstimatorTourService", EstimatorTourService);
app.service("JobInfoTourService", JobInfoTourService);
app.service("JobShowTourService", JobShowTourService);
app.service("Repository", Repository);
app.service("HandlebarsHelperService", HandlebarsHelperService);
app.factory("DirtyWatcher", DirtyWatcher);
app.factory("EventingFactory", EventingService);
app.factory("FileQueueFactory", FileQueueInit);
app.factory("MeasurementImporterFactory", MeasurementImporterFactory);
app.directive("rsfPermissions", RsfPermissions.factory());
app.directive("rsfConfirmationDialog", RsfConfirmationDialog.factory());
app.directive("decimalPlaces", DecimalPlaces.factory());
app.directive("jobAnalytics", JobAnalytics.factory());
app.directive("productColorDropdown", ProductColorDropdown.factory());
app.directive("fakeFormAction", FakeFormAction.factory());
app.directive("embedSrc", EmbedSrc.factory());
app.directive("setFocus", SetFocus.factory());
app.directive("scrollIf", ScrollIf.factory());
app.directive("sortableTab", RsfTab.factory());
app.directive("uiSref", ["$timeout", RsfSref]);
app.component("rsfSpinner", new SpinnerComponent());
app.component("toolDisplay", new ToolDisplayComponent());
app.component("rsfDrawing", new DrawingComponent());
app.component("refreshButton", new RefreshButtonComponent());
app.component("gmapsAutocomplete", new GmapsAutocompleteComponent());
app.component("leafletMap", new LeafletMapComponent());
app.component("rsfMap", new RsfMapComponent());
app.filter("humanize", HumanizeFilter.factory);
app.filter("startFrom", StartFromFilter.factory);
app.constant("valid_uom", valid_uom);
app.constant("valid_kind", valid_kind);
app.constant("US_STATES_ABBREV", us_states_abbreviation);
app.constant("COUNTRIES_ABBREV", countries_abbreviation);

app.component("learnMoreAds", react2angular(LearnMoreAds, ["index", "controller"]));
app.component("pdfDisplay", react2angular(PdfDisplay, ["pdfUrl", "disabledFeatures"]));
app.component("saveablePdfDisplay", react2angular(SaveablePdfDisplay, ["job", "pdfUrl", "disabledFeatures"]));
app.component("flashAlert", react2angular(FlashAlert));
app.component("inviteModal", react2angular(InviteModal));
app.component("screenShare", react2angular(ScreenShare, null, ["$scope", "ScreenShareService", "$state"]));
app.component("notifer", react2angular(Notifier));
app.component("reactSelectWrapper", react2angular(ReactSelectWrapper, ["defaultValue", "available", "onChange"]));
app.component("screenShareToolbar", react2angular(Toolbar));
app.component("orgTabs", react2angular(OrgTabs));
app.component("jobTabs", react2angular(JobTabs));
app.component("documentLink", react2angular(NgDocumentLink, ["document", "iconOnly"]));
app.component("pageTitleUpdater", react2angular(PageTitleUpdater));
app.component("salesProcess", react2angular(SalesProcess));

export default app;
