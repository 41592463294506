import * as ng from "angular";
import { JobShowCtrl } from "@rsfApp/app/src/Jobs/JobShowCtrl";
import { GafJobListCtrl } from "app/src/Jobs/GafJobListCtrl";
import { GafJobFetcherService } from "app/src/Jobs/GafJobFetcherService";
import { ToolTabCtrl } from "@rsfApp/app/src/Jobs/tabs/ToolTabCtrl";
import { EstimateTabCtrl } from "@rsfApp/app/src/Jobs/tabs/EstimateTabCtrl";
import { EstimatesTabCtrl } from "@rsfApp/app/src/Jobs/tabs/EstimatesTabCtrl";
import { MeasurementTabCtrl } from "@rsfApp/app/src/Jobs/tabs/MeasurementTabCtrl";
import { HistoryTabCtrl } from "@rsfApp/app/src/Jobs/tabs/HistoryTabCtrl";
import { GafInfoTabCtrl } from "app/src/Jobs/tabs/InfoTabCtrl";
import { InspectionTabCtrl } from "app/src/Jobs/tabs/InspectionTabCtrl";
import { DesignToolTabCtrl } from "@rsfApp/app/src/Jobs/tabs/DesignToolTabCtrl";
import { ProposalTabCtrl } from "@rsfApp/app/src/Jobs/tabs/ProposalTabCtrl";
import { ContractTabCtrl } from "@rsfApp/app/src/Jobs/tabs/ContractTabCtrl";
import { AreaCalculatorCtrl } from "@rsfApp/app/src/Jobs/tabs/AreaCalculatorCtrl";
import { MeasurementEditorCtrl } from "@rsfApp/app/src/Jobs/tabs/MeasurementEditorCtrl";
import { MeasurementEditorComponent } from "@rsfApp/app/src/Jobs/MeasurementEditorComponent";
import { UploaderCtrl } from "@rsfApp/app/src/Jobs/uploader/UploaderCtrl";
import { PropertyDataTabCtrl } from "@rsfApp/app/src/Jobs/tabs/PropertyDataTabCtrl";
import { OrderCoreLogicCtrl } from "@rsfApp/app/src/Jobs/tabs/OrderCoreLogicCtrl";
import { ImportMeasurementsCtrl } from "@rsfApp/app/src/Jobs/tabs/ImportMeasurementsCtrl";
import { CoreLogicOrderModalCtrl } from "@rsfApp/app/src/Jobs/tabs/CoreLogicOrderModalCtrl";
import { VisualizationModalCtrl } from "@rsfApp/app/src/Jobs/tabs/VisualizationModalCtrl";
import { EmailModal } from "@rsfApp/app/src/Jobs/tabs/EmailModal";
import { PinDropComponent } from "@rsfApp/app/src/Jobs/Components/PinDropComponent";
import { ScreenShareService } from "@rsfApp/app/src/Jobs/ScreenShareService";
import { ProductOptionSelectorComponent } from "@rsfApp/app/src/ProductOptionSelector/ProductOptionSelectorComponent";
import { RapidAreaComponent } from "@rsfApp/app/src/RsfMap/RapidAreaComponent";
import { SatelliteMeasureComponent } from "@rsfApp/app/src/RsfMap/SatelliteMeasureComponent";
import { DeckWizardComponent } from "@rsfApp/app/src/RsfMap/DeckWizardComponent";
import { PoolToolComponent } from "@rsfApp/app/src/RsfMap/PoolToolComponent";
import { LearnMoreCtrl } from "@rsfApp/app/src/Common/Ads/LearnMoreCtrl";
import { react2angular } from "react2angular";
import InspectionContainer from "app2/src/Inspections/InspectionContainer";
import PresentationContainer from "@rsfApp/app2/src/components/PricePresentation/PresentationContainer";
import PresentationBuilderContainer from "app2/src/Components/PresentationBuilder/PresentationBuilderContainer";
import PresentationViewerContainer from "app2/src/Components/PresentationViewer/PresentationViewerContainer";
import ProfileOverview from "app2/src/Components/ProfileOverview";
import CustomerStats from "app2/src/Components/CustomerStats";
import NextAppointment from "app2/src/Components/NextAppointment";
import BusyCalendar from "app2/src/Components/BusyCalendar";
import SidebarAddAppt from "app2/src/Components/SidebarAddAppt";
import { SidebarCtrl } from "app/src/Sidebar/SidebarCtrl";
import ImportCsv from "@rsfApp/app2/src/components/JobList/ImportCsv";
import Filter from "app2/src/Components/Filter";
import JobQueryForm from "@rsfApp/app2/src/components/JobList/JobQueryForm";
import DownloadDocumentButton from "@rsfApp/app2/src/components/Document/DownloadDocumentButton";
import PricePresentationButton from "@rsfApp/app2/src/components/JobTabs/EstimateTab/IngagePricePresentation/PricePresentationButton";
import ReportList from "@rsfApp/app2/src/components/JobTabs/MeasurementTab/ReportList";
import { ImageUploadComponent } from "@rsfApp/app/src/Jobs/Components/ImageUploadComponent";

export class GafJobRoutes {
  public static load(app: ng.IModule) {
    app.controller("JobListCtrl", GafJobListCtrl);
    app.controller("JobShowCtrl", JobShowCtrl);
    app.controller("UploaderCtrl", UploaderCtrl);
    app.controller("LearnMoreCtrl", LearnMoreCtrl);
    app.controller("SidebarCtrl", SidebarCtrl);

    // Tab Ctrls
    app.controller("EstimateTabCtrl", EstimateTabCtrl);
    app.controller("EstimatesTabCtrl", EstimatesTabCtrl);
    app.controller("HistoryTabCtrl", HistoryTabCtrl);
    app.controller("InfoTabCtrl", GafInfoTabCtrl);
    app.controller("DesignToolTabCtrl", DesignToolTabCtrl);
    app.controller("MeasurementTabCtrl", MeasurementTabCtrl);
    app.controller("ToolTabCtrl", ToolTabCtrl);
    app.controller("ProposalTabCtrl", ProposalTabCtrl);
    app.controller("ContractTabCtrl", ContractTabCtrl);
    app.controller("InspectionTabCtrl", InspectionTabCtrl);

    app.controller("AreaCalculatorCtrl", AreaCalculatorCtrl);
    app.controller("MeasurementEditorCtrl", MeasurementEditorCtrl);
    app.controller("PropertyDataTabCtrl", PropertyDataTabCtrl);
    app.controller("OrderCoreLogicCtrl", OrderCoreLogicCtrl);
    app.controller("ImportMeasurementsCtrl", ImportMeasurementsCtrl);
    app.controller("CoreLogicOrderModalCtrl", CoreLogicOrderModalCtrl);
    app.controller("VisualizationModalCtrl", VisualizationModalCtrl);
    app.controller("EmailModal", EmailModal);

    app.component("measurementEditorComponent", new MeasurementEditorComponent());
    app.component("pinDrop", new PinDropComponent());
    app.component("jobImageUpload", new ImageUploadComponent());
    app.component("productOptionSelector", new ProductOptionSelectorComponent());
    app.component("rapidArea", new RapidAreaComponent());
    app.component("satelliteMeasure", new SatelliteMeasureComponent());
    app.component("deckWizard", new DeckWizardComponent());
    app.component("poolTool", new PoolToolComponent());
    app.component("pricePresentation", react2angular(PresentationContainer, ["estimateids", "orgid"], ["$state"]));
    app.component("profileOverview", react2angular(ProfileOverview));
    app.component("customerStats", react2angular(CustomerStats, ["stats"]));
    app.component("nextAppointment", react2angular(NextAppointment, ["response"], ["$state"]));
    app.component("busyCalendar", react2angular(BusyCalendar, ["response"]));
    app.component("sidebarAddAppt", react2angular(SidebarAddAppt, ["job", "addAppointment"]));
    app.component("downloadDocumentButton", react2angular(DownloadDocumentButton, ["documentId", "signed"]));
    app.component("pricePresentationButton", react2angular(PricePresentationButton, ["isEstimateDirty"]));
    app.component("reportList", react2angular(ReportList));

    app.service("JobFetcher", GafJobFetcherService);
    app.service("ScreenShareService", ScreenShareService);

    app.component(
      "presentationBuilder",
      react2angular(
        PresentationBuilderContainer,
        ["job"],
        ["$state", "EstimatorService", "$scope", "DirtyWatcher", "$analytics"]
      )
    );
    app.component(
      "presentationViewer",
      react2angular(PresentationViewerContainer, [], ["$state", "$stateParams", "Note", "$analytics"])
    );
    app.component(
      "inspectionContainer",
      react2angular(
        InspectionContainer,
        ["job", "queue", "email", "download"],
        ["EstimatorService", "$scope", "DirtyWatcher", "$analytics"]
      )
    );
    app.component("jobQueryForm", react2angular(JobQueryForm, ["toggleJobQueryForm", "columnSize", "onSubmit"]));
    app.component(
      "filter",
      react2angular(
        Filter,
        [
          "orgId",
          "toggleStateFilter",
          "statuses",
          "gafStateName",
          "colorFromRawState",
          "includeArchivedQuery",
          "setJobTypeFilter",
        ],
        ["$state", "Pretty"]
      )
    );
    app.component("importCsv", react2angular(ImportCsv, ["refreshQuery"], []));
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider
      .state("job_header", <ng.ui.IState>{
        abstract: true,
        views: {
          header: {
            templateUrl: "gaf/src/Jobs/sidebar.html",
            controller: "SidebarCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("root.job_list", <ng.ui.IState>{
        url: "/jobs?status=",
        views: {
          "container@": {
            templateUrl: "gaf/src/Jobs/list.html",
            controller: "JobListCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show", <ng.ui.IState>{
        url: "/jobs/:id?embedded&header",
        abstract: true,
        views: {
          "container@": {
            templateUrl: "gaf/src/Jobs/show.html",
            controller: "GafHeaderCtrl",
            controllerAs: "$ctrl",
          },
          "jobShow@job_header.job_show": {
            templateUrl: "gaf/src/Jobs/tabs/info.html",
            controller: "InfoTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.info", <ng.ui.IState>{
        url: "/info",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "gaf/src/Jobs/tabs/info.html",
            controller: "InfoTabCtrl",
            controllerAs: "$ctrl",
          },
          "history@job_header.job_show.info": {
            templateUrl: "gaf/src/Jobs/tabs/history.html",
            controller: "HistoryTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.property", <ng.ui.IState>{
        url: "/property",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/property.html",
            controller: "PropertyDataTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.tools", <ng.ui.IState>{
        url: "/tools",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "gaf/src/Jobs/tabs/tools.html",
            controller: "DesignToolTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimate", <ng.ui.IState>{
        url: "/estimates/:estimate_id",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimate.html",
            controller: "EstimateTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimate.price_presentation", <ng.ui.IState>{
        url: "/price_presentation",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimate.html",
            controller: "EstimateTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.estimates", <ng.ui.IState>{
        url: "/estimates",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/estimates.html",
            controller: "EstimatesTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.measurements", <ng.ui.IState>{
        url: "/measurements/:path",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/measurements.html",
            controller: "MeasurementTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        params: {
          path: { type: "string", raw: true },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.history", <ng.ui.IState>{
        url: "/history",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/history.html",
            controller: "HistoryTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("presentationviewer", <ng.ui.IState>{
        url: "/jobs/:id/presentationviewer?:presentation_id",
        views: {
          header: {
            templateUrl: "gaf/src/Jobs/presentation_header.html",
            controller: "GafHeaderCtrl",
            controllerAs: "$ctrl",
          },
          container: {
            component: "presentationViewer",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.sales_process", <ng.ui.IState>{
        url: "/sales-process",
        views: {
          "jobShow@job_header.job_show": {
            component: "salesProcess",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.design_tools", <ng.ui.IState>{
        url: "/design-tools",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "src/Jobs/tabs/design_tools.html",
            controller: "DesignToolTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.signing", <ng.ui.IState>{
        url: "/:job_tab/signing/:signed_document_id",
        views: {
          "jobShow@job_header.job_show": {},
        },
      })
      .state("job_header.job_show.price", <ng.ui.IState>{
        url: "/price?estimateids&orgid",
        views: {
          "jobShow@job_header.job_show": {
            component: "pricePresentation",
          },
        },
        resolve: {
          ...resolveAuth,
          estimateids: [
            "$stateParams",
            ($stateParams) => {
              return $stateParams.estimateids;
            },
          ],
          orgid: [
            "$stateParams",
            ($stateParams) => {
              return $stateParams.orgid;
            },
          ],
        },
      })
      .state("upload_job_images", <ng.ui.IState>{
        url: "/jobs/upload/:key?token=?folder_id",
        views: {
          header: {
            templateUrl: "src/Header/viewer.html",
          },
          "container@": {
            templateUrl: "src/Jobs/uploader/uploader.html",
            controller: "UploaderCtrl",
            controllerAs: "$ctrl",
          },
        },
      })
      .state("job_header.job_show.inspection", <ng.ui.IState>{
        url: "/inspection",
        views: {
          "jobShow@job_header.job_show": {
            templateUrl: "gaf/src/Jobs/tabs/inspection.html",
            controller: "InspectionTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("root.styles", <ng.ui.IState>{
        url: "/styles",
        views: {
          "container@": {
            templateUrl: "gaf/src/Jobs/styles.html",
            controller: "InfoTabCtrl",
            controllerAs: "$ctrl",
          },
        },
        resolve: resolveAuth,
      })
      .state("job_header.job_show.react-job-tabs", <ng.ui.IState>{
        url: "/:path",
        params: {
          path: { type: "string", raw: true },
        },
        views: {
          "jobShow@job_header.job_show": {},
        },
      });
  }
}
