import { InfoTabCtrl } from "@rsfApp/app/src/Jobs/tabs/InfoTabCtrl";
import { IJobFetcherService } from "@rsfApp/app/src/Jobs/JobFetcherService";
import { ISession } from "@rsfApp/app/src/Common/SessionService";
import { IFlash, FlashLevels } from "@rsfApp/app/src/Common/FlashService";
import * as angulartics from "angulartics";
import { IDirtyWatcher } from "@rsfApp/app/src/Common/DirtyWatcher";
import { IRepository } from "@rsfApp/app/src/Common/Repository";
import { IPretty } from "@rsfApp/app/src/Common/PrettyNameService";
import { JobInfoTourService } from "@rsfApp//app/src/Common/Tours/JobInfoTourService";
import { RsfRootScope } from "@rsfApp//app/src/Common/RsfRootScope";
import { IEventingFactory } from "@rsfApp//app/src/Common/EventingFactory";
import { IPhoneNumber } from "@rsfApp/app/src/Models/PhoneNumber";
import { AddressRecord, fromJSON } from "@rsfApp/app2/src/records/Address";
import { RootStoreType } from "@rsfApp/app2/src/store";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import * as integrationsViewActions from "@rsfApp/app2/src/reducers/components/integrationsView.actions";
import { push } from "connected-react-router/immutable";

export class GafInfoTabCtrl extends InfoTabCtrl {
  public showToggle: any = {
    history: false,
    map: true,
  };
  public addressRecord: AddressRecord;
  public store: RootStoreType;

  public static $inject = [
    "JobFetcher",
    "Repository",
    "Flash",
    "NgMap",
    "Session",
    "US_STATES",
    "$q",
    "$timeout",
    "DirtyWatcher",
    "$scope",
    "$state",
    "$stateParams",
    "$window",
    "$analytics",
    "COUNTRIES",
    "Pretty",
    "GeoCoder",
    "JobInfoTourService",
    "$rootScope",
    "EventingFactory",
  ];
  constructor(
    public JobFetcher: IJobFetcherService,
    public Repository: IRepository,
    public Flash: IFlash,
    public ngMap: angular.map.INgMap,
    public Session: ISession,
    public US_STATES: string[],
    public $q: ng.IQService,
    public $timeout: ng.ITimeoutService,
    public DirtyWatcher: IDirtyWatcher,
    public $scope: ng.IScope,
    public $state: ng.ui.IStateService,
    public $stateParams: ng.ui.IStateParamsService,
    public $window: ng.IWindowService,
    protected $analytics: angulartics.IAnalyticsService,
    public COUNTRIES: string[],
    public Pretty: IPretty,
    public GeoCoder: any,
    public JobInfoTourService: JobInfoTourService,
    public $rootScope: RsfRootScope,
    public EventingFactory: IEventingFactory
  ) {
    super(
      JobFetcher,
      Repository,
      Flash,
      ngMap,
      Session,
      US_STATES,
      $q,
      $timeout,
      DirtyWatcher,
      $scope,
      $state,
      $stateParams,
      $window,
      $analytics,
      COUNTRIES,
      Pretty,
      GeoCoder,
      JobInfoTourService,
      $rootScope,
      EventingFactory
    );

    this.store = StoreRegistry.getStore();
  }

  public openQuickMeasureModal() {
    this.addressRecord = fromJSON(this.job.address);
    this.store.dispatch(integrationsViewActions.Actions.openQuickMeasureModal());
  }

  public addAppointment = () => {
    this.store.dispatch(push(`/jobs/${this.job.id}/appointments/calendar`));
  };

  public gafNameFromRawState() {
    if (this.Pretty.name[this.job.state]) {
      return this.Pretty.name[this.job.state];
    }

    return this.nameFromRawState();
  }

  public phoneNumberChanged = (phone: IPhoneNumber) => {
    let newVal = "";
    let extra = "";
    if (phone.number.length <= 12) {
      newVal = phone.number.replace(/\D/g, "");
    } else {
      newVal = phone.number.slice(0, 12).replace(/\D/g, "");
      extra = phone.number.slice(12);
    }

    if (newVal.length === 0) {
      newVal = "";
    } else if (newVal.length <= 3) {
      // don't show braces for empty groups at the end
      newVal = newVal.replace(/^(\d{0,3})/, "$1");
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, "$1-$2");
    } else {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(.*)/, "$1-$2-$3");
    }

    phone.number = `${newVal}${extra}`.trim();
  };

  private toggleView(section) {
    this.showToggle[section] = !this.showToggle[section];
  }
}
