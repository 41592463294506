import { ProfileCtrl } from "@rsfApp/app/src/Profile/ProfileCtrl";

export class GafProfileCtrl extends ProfileCtrl {
  public jobListViewValues: any = [
    { id: true, text: "Grid View" },
    { id: false, text: "List View" },
  ];

  // Override, appears to be a difference in load timing between GAF/OCC.
  // Likely the fact that with GAF we have to make another call to the server to get the User?
  public combinedToJson() {
    if (!this.user || !this.preferences) {
      return;
    }
    return angular.toJson(this.user) + angular.toJson(this.preferences);
  }
}
