import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Row, Col } from "react-bootstrap";
import * as eventActions from "@rsfApp/app2/src/reducers/event.actions";
import * as Calendar from "react-calendar-pane";
import * as moment from "moment";
import { UserRecord, getFullName } from "@rsfApp/app2/src/records/UserRecord";

const mapStateToProps = (state: RootState, ownProps: IBusyCalendarProps) => {
  return {
    currentUser: state.getIn(["users", "currentUser"]),
    busyInfo: state.getIn(["events", "busyInfo"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IBusyCalendarProps) => {
  return {
    loadBusy: (start: string, end: string) => {
      return dispatch(eventActions.AsyncActions.fetchBusyInfo(start, end));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IBusyCalendarProps {
  response: any;
  dateSelected: (date: moment.Moment) => void;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IBusyCalendarProps;

export interface IBusyCalendarState {
  step: number;
  loadingBusy: boolean;
}

class BusyCalendar extends React.Component<Props, IBusyCalendarState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      step: 0,
      loadingBusy: false,
    };

    this.onSelect = this.onSelect.bind(this);
  }

  public onSelect(date, previousDate, currentMonth) {
    const { dateSelected } = this.props;

    if (dateSelected) {
      dateSelected(date);
    }
  }

  public render() {
    return (
      <Row>
        <Col sm={12}>
          <div className="form-section no-border">
            <div className="busy-calendar">
              <Calendar
                date={moment(moment().format("DD/MM/YYYY"), "DD/MM/YYYY")}
                dayRenderer={(args) => {
                  const { handleClick, date } = args;
                  const isBusy = this.isBusy(date);

                  return (
                    <a
                      className={`Day-inner${isBusy ? " busy" : ""}`}
                      href={"#" + date.format("YYYY-MM-DD")}
                      onClick={() => handleClick(date)}>
                      {date.format("D")}
                    </a>
                  );
                }}
                onViewChanged={this.viewChanged}
                onSelect={this.onSelect}
              />
            </div>
          </div>
        </Col>
      </Row>
    );
  }

  protected viewChanged = (month: moment.Moment, start: moment.Moment, end: moment.Moment) => {
    const { loadBusy } = this.props;

    loadBusy(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  };

  protected isBusy = (date: moment.Moment): boolean => {
    const { busyInfo } = this.props;

    if (!busyInfo) {
      return;
    }

    return busyInfo.get(date.format("YYYY-MM-DD"));
  };
}

export default connector(BusyCalendar);
