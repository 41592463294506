import { IUser } from "@rsfApp/app/src/Models/User";
import * as angulartics from "angulartics";
import { IBaseConfig } from "@rsfApp/app/src/Common/IBaseConfig";
import { embeddedService, IEmbeddedEvent } from "@rsfApp/app2/src/services/embedded.service";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import { cognitoAuth as gafAuth } from "../../../app2/src/Authentication/CognitoAuth.service";
import * as authActions from "@rsfApp/app2/src/reducers/auth.actions";

export class GafLoginCtrl {
  public spinnerPromise: Promise<any>;
  public user: IUser;
  public errors: string[];
  public goToRoute: string;
  public goToParams: any;
  public invalidRoutes: string[] = ["root.logout"];

  public static $inject = ["$state", "$scope", "$analytics", "BaseConfig", "$stateParams"];
  constructor(
    private $state: ng.ui.IStateService,
    private $scope: ng.IScope,
    public $analytics: angulartics.IAnalyticsService,
    public BaseConfig: IBaseConfig,
    public $stateParams: ng.ui.IStateParamsService
  ) {
    this.receiveMessage = this.receiveMessage.bind(this);
    this.goToRoute = this.BaseConfig.DEFAULT_ROUTE;
    this.goToParams = {};

    this.spinnerPromise = new Promise((_resolve) => {});

    if (
      $stateParams.routeTo &&
      $stateParams.routeTo.name &&
      !_.include(this.invalidRoutes, $stateParams.routeTo.name)
    ) {
      this.goToRoute = $stateParams.routeTo.name;
      this.goToParams = $stateParams.routeParams;
    }

    embeddedService.addListener(this.receiveMessage);

    $scope.$on("$destroy", () => {
      embeddedService.removeListener(this.receiveMessage);
    });

    gafAuth.login({ goToRoute: this.goToRoute, goToParams: this.goToParams }, "");

    embeddedService.sendEvent("RSF.loaded");
  }

  public receiveMessage(event: IEmbeddedEvent) {
    switch (event.event) {
      case "RSF.auth":
        this.setAuth(event.payload);
        this.goTo(event.payload.user);
        break;
    }
    this.$scope.$digest();
  }

  protected goTo(user: any) {
    if (this.goToRoute) {
      this.$analytics.eventTrack("embeddedLogin", {
        category: "User",
        label: "authorization",
        userId: user.id,
        user: user.id,
        org: user.org.id,
      });
      this.$state.go(this.goToRoute, this.goToParams);
    }
  }

  protected setAuth(auth: any) {
    return StoreRegistry.getStore().dispatch(
      authActions.AsyncActions.fromData(auth.authToken, auth.user, auth.refreshToken)
    );
  }
}
