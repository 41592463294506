import { RootState } from "@rsfApp/app2/src/reducers";
import { createSelector } from "reselect";
import { PresentationRecord } from "@rsfApp/app2/src/records/Presentation";
import { Map, List } from "immutable";

const presentationsById = (state: RootState) => state.getIn(["presentations", "byId"]);
const orgPresentationIds = (state: RootState, props: any) => {
  if (!props.orgId) {
    return List();
  }

  return state.getIn(["presentations", "presentationsByOrgId", parseInt(props.orgId), "presentations"]);
};
const jobPresentationIds = (state: RootState, props: any) => {
  if (!props.jobId) {
    return List();
  }

  return state.getIn(["presentations", "presentationsByJobId", parseInt(props.jobId), "presentations"]);
};
const slideIds = (state: RootState) => state.getIn(["presentations", "slidesById"]);

export const getOrgPresentations = createSelector(
  [presentationsById, orgPresentationIds],
  (byId: Map<number, PresentationRecord>, presentations: List<number>) => {
    if (!presentations) {
      return List();
    }

    const interim = presentations.map((id) => byId.get(id)).sortBy((d) => d.sort_order);
    return interim;
  }
);

export const getJobPresentations = createSelector(
  [presentationsById, jobPresentationIds],
  (byId: Map<number, PresentationRecord>, presentations: List<number>) => {
    if (!presentations) {
      return List();
    }

    const interim = presentations.map((id) => byId.get(id)).sortBy((d) => d.sort_order);
    return interim;
  }
);

export const getFirstJobPresentation = createSelector(
  [presentationsById, jobPresentationIds],
  (byId: Map<number, PresentationRecord>, presentations: List<number>) => {
    if (!presentations) {
      return undefined;
    }

    if (presentations.size <= 0) {
      return null;
    }

    return byId.get(presentations.first({} as any));
  }
);
