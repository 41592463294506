import * as React from "react";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { Row, Col, Form, Dropdown, Button } from "react-bootstrap";
import { Map, List } from "immutable";
import { ThunkDispatch } from "redux-thunk";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { JobAttributeTypes } from "@rsfApp/app2/src/records/OrgRecord";
import { JobObjectRecord } from "@rsfApp/app2/src/records/JobObject";
import { AsyncActions } from "@rsfApp/app2/src/reducers/jobAttribute.actions";
import { getActiveJobAttributesList } from "@rsfApp/app2/src/selectors/jobAttribute.selectors";

const mapStateToProps = (state: RootState, ownProps: FilterProps) => {
  return {
    jobTypes: getActiveJobAttributesList(state, { orgId: ownProps.orgId, jobAttrType: JobAttributeTypes.job_type }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: FilterProps) => {
  return {
    loadJobTypes: () => {
      return dispatch(AsyncActions.getJobAttributes(ownProps.orgId, JobAttributeTypes.job_type));
      // return dispatch(AsyncActions.getJobTypes({ org_id: ownProps.orgId }));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface FilterProps {
  orgId: number;
  toggleStateFilter: (any) => any;
  statuses: any;
  setJobTypeFilter: (id: number) => void;
  gafStateName: (rawState: string, mapped: string) => any;
  colorFromRawState: (state: string) => any;
  includeArchivedQuery: (archived: boolean) => any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & FilterProps;

export interface FilterState {
  jobTypeId: string;
  selectedStatuses: Map<string, boolean>;
  name: string;
}

class Filter extends React.Component<Props, FilterState> {
  public constructor(props) {
    super(props);

    this.state = {
      selectedStatuses: Map(),
      name: "None",
      jobTypeId: "",
    };

    this.updateStatuses = this.updateStatuses.bind(this);
  }

  public componentDidMount() {
    const { jobTypes, loadJobTypes } = this.props;
    if (jobTypes.size <= 0) {
      loadJobTypes();
    }
  }

  public updateStatuses(newSelectedStatuses: Map<string, boolean>) {
    const { statuses, gafStateName } = this.props;

    const selected = [];
    Object.keys(statuses || {}).forEach((k) => {
      if (newSelectedStatuses.get(k)) {
        selected.push(gafStateName(k, statuses[k]));
      }
    });

    if (selected.length > 0) {
      this.setState({ selectedStatuses: newSelectedStatuses, name: selected.join(", ") });
    } else {
      this.setState({ selectedStatuses: newSelectedStatuses, name: "None" });
    }
  }

  public render() {
    const {
      setJobTypeFilter,
      toggleStateFilter,
      statuses,
      gafStateName,
      colorFromRawState,
      includeArchivedQuery,
      jobTypes,
    } = this.props;
    const { selectedStatuses, name, jobTypeId } = this.state;

    return (
      <Row className="pt-2">
        <Col sm={12} md={6}>
          <div className="form-group">
            <label>Statuses</label>
            <Dropdown className="filter-bar">
              <Dropdown.Toggle id="filter-by-status" className="form-control">
                {selectedStatuses.size <= 0 ? "None" : name}
                <img src="/assets/images/down.1d61a411.svg" />
              </Dropdown.Toggle>
              <Dropdown.Menu as="ul" className="full-width">
                {Object.keys(statuses || {}).map((k, idx) => {
                  return (
                    <li
                      key={idx}
                      style={{
                        borderLeftColor: colorFromRawState(k),
                        borderLeftStyle: "solid",
                        borderLeftWidth: "5px",
                      }}>
                      <a
                        onClick={() => {
                          toggleStateFilter(k);
                          this.updateStatuses(selectedStatuses.set(k, selectedStatuses.get(k) ? false : true));
                        }}>
                        {selectedStatuses.get(k) && <i className="fa fa-check" />}
                        {gafStateName(k, statuses[k])}
                      </a>
                    </li>
                  );
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group>
            <Form.Label className="p-copy">Job Type</Form.Label>
            <Form.Control
              id="jobType"
              as="select"
              type="text"
              className="p-copy"
              value={jobTypeId || ""}
              onChange={(e: any) => {
                this.setState({ jobTypeId: e.target.value });
                setJobTypeFilter(e.target.value);
              }}>
              <option value="">All Job Types</option>
              {jobTypes.map((jt, idx) => (
                <option key={idx} value={jt.id.toString()} title={jt.description}>
                  {jt.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm={12} md={6}>
          <Form.Group className="d-flex flex-row align-items-center">
            <Form.Check onChange={(e: any) => includeArchivedQuery(e.target.checked)} />
            <label>&nbsp;Include Archived</label>
          </Form.Group>
        </Col>
      </Row>
    );
  }
}

export default connector(Filter);
