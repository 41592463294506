import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { UserRecord, getFullName } from "@rsfApp/app2/src/records/UserRecord";
import * as config from "react-global-configuration";
import { currentUserAccesses } from "@rsfApp/app2/src/selectors/access.selectors";
import { denormalizedReduxUser } from "@rsfApp/app2/src/selectors/user.selectors";
import { org as orgById } from "@rsfApp/app2/src/selectors/org.selectors";
import { List, getIn } from "immutable";
import { AccessRecord } from "@rsfApp/app2/src/records/UserRecord";
import { OrgRecord } from "@rsfApp/app2/src/records/OrgRecord";
import { CustomLink } from "@rsfApp/app2/src/components/Common/CustomLink";

const mapStateToProps = (state: RootState, ownProps: IProfileOverviewProps) => {
  const currentUser = denormalizedReduxUser(state);
  const orgId = getIn(currentUser, ["org_id"], null);
  return {
    currentUser: currentUser,
    currentOrg: orgById(state, { orgId }),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: IProfileOverviewProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IProfileOverviewProps {}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IProfileOverviewProps;

export interface IProfileOverviewState {}

class ProfileOverview extends React.Component<Props, IProfileOverviewState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { currentUser, currentOrg } = this.props;
    if (!currentUser || !currentOrg) {
      return <div />;
    }

    return (
      <Container>
        <Row>
          <Col sm={12}>
            <Row>
              <Col sm={10}>
                <div className="profile-font d-flex flex-row">
                  <div className="text-truncate" style={{ maxWidth: "200px" }}>
                    Hi {getFullName(currentUser)}
                  </div>
                  &nbsp;&nbsp;|&nbsp;&nbsp;
                  <a href="/logout">Logout</a>
                </div>
              </Col>
              <Col sm={2}>
                <Dropdown className="pull-right profile-gear">
                  <Dropdown.Toggle className="profile-dropdown" id="dropdown">
                    <i className="fa fa-gear fa-24" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {currentUser.accesses_count > 1 && (
                      <Dropdown.Item href="/orgs/accesses">Select Organization</Dropdown.Item>
                    )}
                    <Dropdown.Item as="div">
                      <CustomLink tag="a" to="/profile">
                        Profile
                      </CustomLink>
                    </Dropdown.Item>
                    <Dropdown.Item href={config.get("SUPPORT_URL")} target="_blank">
                      Support Resources
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Version: {config.get("VERSION")}</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <p className="profile-font">{currentOrg.name}</p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <p className="profile-font">
                  {currentOrg.address.city} {currentOrg.address.state} {currentOrg.address.postal_code}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connector(ProfileOverview);
