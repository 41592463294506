import { EnterCtrl } from "app/src/PaymentInfo/EnterCtrl";

export class GafPaymentInfoRoutes {
  public static load(app: ng.IModule) {
    app.controller("EnterCtrl", EnterCtrl);
  }

  public static configure($stateProvider: ng.ui.IStateProvider, resolveAuth): void {
    $stateProvider.state("root.payment_info", <ng.ui.IState>{
      url: "/payment_info/:id",
      views: {
        "container@": {
          templateUrl: "gaf/src/PaymentInfo/enter.html",
          controller: "EnterCtrl",
          controllerAs: "$ctrl",
        },
      },
    });
  }
}
