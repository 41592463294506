import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "@rsfApp/app2/src/reducers";
import { Row, Col } from "react-bootstrap";
import { List } from "immutable";
import _track, { Track, TrackingProp } from "react-tracking";
import { Dispatch, TrackingData } from "@rsfApp/app2/src/helpers/Analytics";
import * as presentationActions from "@rsfApp/app2/src/reducers/presentation.actions";
import * as presentationTemplateActions from "@rsfApp/app2/src/reducers/presentationTemplate.actions";
import * as estimateActions from "@rsfApp/app2/src/reducers/estimate.actions";
import SpinnerComponent from "@rsfApp/app2/src/components/SpinnerComponent";
import DynamicPresentationComponent from "./DynamicPresentationComponent";
import InspectionComponent from "./InspectionComponent";
import PresentationOverview from "./PresentationOverview";
import { PresentationRecord } from "@rsfApp/app2/src/records/Presentation";
import { getOrgPresentations, getFirstJobPresentation } from "./presentationSelector";
import { currentJob } from "@rsfApp/app2/src/selectors/job.selectors";
import * as builderService from "./PresentationBuilder.service";
import { PresentationTemplateRecord } from "@rsfApp/app2/src/records/PresentationTemplate";
import { Nullable } from "@rsfApp/app2/src/records";
import { IndexPresentationsRecord } from "@rsfApp/app2/src/reducers/presentation.reducer";
import { StoreRegistry } from "@rsfApp/app2/src/storeRegistry";
import DirtyWatcher from "@rsfApp/app2/src/components/Common/DirtyWatcher";

const mapStateToProps = (state: RootState, ownProps: IPresentationBuilderContainerProps) => {
  const job = currentJob(state);
  return {
    jobPresentation: getFirstJobPresentation(state, { jobId: job.id }),
    jobPresentations: state.getIn(["presentations", "presentationsByJobIdf", job.id], new IndexPresentationsRecord()),
    orgPresentations: getOrgPresentations(state, { orgId: job.org_id }),
    slidesById: state.getIn(["presentations", "slidesById"]),
    job,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, {}, RootActions>,
  ownProps: IPresentationBuilderContainerProps
) => {
  return {
    loadJobPresentation: (jobId: number, orgPresentations: List<PresentationRecord>, inspectionId?: number) => {
      return dispatch(presentationActions.AsyncActions.listJobPresentations(jobId, ["slides"])).then(
        (presentations) => {
          if (presentations.size <= 0) {
            const p = builderService.buildInitialJobPresentation(jobId, inspectionId);
            dispatch(presentationActions.Actions.receivePresentation(p));
            return p;
          }

          const p = builderService.setupDynamicLinkSlides(
            presentations.first() as PresentationRecord,
            orgPresentations
          );

          dispatch(presentationActions.Actions.receivePresentation(p));
          return p;
        }
      );
    },
    loadOrgPresentations: (orgId: number) => {
      return dispatch(presentationActions.AsyncActions.listOrgPresentations(orgId, ["slides"]));
    },
    saveDynamicPresentation: (jobId: number, pres: PresentationRecord, orgPresentations: List<PresentationRecord>) => {
      dispatch(presentationActions.Actions.fetchPresentation(pres.id));
      if (pres.id <= 0) {
        return dispatch(presentationActions.AsyncActions.addJobPresentation(jobId, pres)).then((a) => {
          dispatch(presentationActions.Actions.removePresentation(pres.id));
          dispatch(presentationActions.Actions.removeJobPresentation(jobId, pres.id));

          a = builderService.setupDynamicLinkSlides(a, orgPresentations);
          dispatch(presentationActions.Actions.receivePresentation(a));
          return a;
        });
      }

      return dispatch(presentationActions.AsyncActions.updatePresentation(pres)).then((a) => {
        a = builderService.setupDynamicLinkSlides(a, orgPresentations);
        dispatch(presentationActions.Actions.receivePresentation(a));

        return a;
      });
    },
    loadTemplates: (orgId: number) => {
      return dispatch(presentationTemplateActions.AsyncActions.listPresentationTemplates(orgId));
    },
    updatePresentation: (presentation: PresentationRecord) => {
      dispatch(presentationActions.Actions.receivePresentation(presentation));
    },
    loadInspection: (inspectionId: number) => {
      dispatch(estimateActions.AsyncActions.getEstimate(inspectionId));
    },
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface IPresentationBuilderContainerProps {
  tracking?: TrackingProp;
}

const track: Track<TrackingData, IPresentationBuilderContainerProps> = _track;

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & IPresentationBuilderContainerProps;

export interface IPresentationBuilderContainerState {
  inspectionId: Nullable<number>;
  savedState: string;
  dirty: boolean;
  isNew: boolean;
}

@track(
  (props: Props) => {
    return {
      category: "Presentation Builder",
      action: "Show",
      job: props.job.id,
      org: props.job.org_id,
    };
  },
  {
    dispatch: Dispatch.dispatch,
    dispatchOnMount: true,
  }
)
class PresentationBuilderContainer extends React.Component<Props, IPresentationBuilderContainerState> {
  public state = {
    inspectionId: null,
    savedState: "",
    dirty: false,
    isNew: false,
  };

  constructor(props: Props) {
    super(props);

    this.applyTemplate = this.applyTemplate.bind(this);
    this.savePresentation = this.savePresentation.bind(this);
  }

  public componentDidMount() {
    const { loadJobPresentation, loadOrgPresentations, loadInspection, loadTemplates, job } = this.props;

    const promises = [loadOrgPresentations(job.org_id)];

    const inspectionId = job.getIn(["inspections", 0, "id"]);
    if (inspectionId) {
      this.setState({ inspectionId });
      promises.push(loadInspection(inspectionId));
    }

    Promise.all(promises).then(() => {
      loadJobPresentation(job.id, this.props.orgPresentations, inspectionId).then((j) => {
        if (j.id <= 0) {
          this.setState({ isNew: true });
        }
        this.resetState();
        this.checkInspectionSlide();
      });
    });

    loadTemplates(job.org_id);
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.jobPresentation && !prevProps.jobPresentation.equals(this.props.jobPresentation)) {
      this.check();
    }
  }

  public render() {
    const { orgPresentations, jobPresentation, jobPresentations, slidesById, job } = this.props;
    const { inspectionId, dirty, isNew } = this.state;

    return (
      <SpinnerComponent localProperty={jobPresentations.loading}>
        <DirtyWatcher check={this.check} reset={this.reset} reactRouter />
        <Row className="presentation-builder">
          <Col sm={12}>
            <table className="table pbuilder-header">
              <tbody>
                <tr>
                  <td>
                    <div>
                      <span className="name">Start Smart</span>
                      <span className="number">01</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span className="name">Analyze Needs</span>
                      <span className="number">02</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span className="name">Offer Solutions</span>
                      <span className="number">03</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span className="name">Close the Sale</span>
                      <span className="number">04</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <Row>
              <Col sm={12}>
                <DynamicPresentationComponent
                  presentation={jobPresentation}
                  inspectionId={inspectionId}
                  orgId={job.org_id}
                  goTo={this.goToPresentation}
                  slidesById={slidesById}
                  dirty={dirty || isNew}
                  applyTemplate={this.applyTemplate}
                  orgPresentations={orgPresentations}
                  save={this.savePresentation}
                />
                <InspectionComponent inspectionId={inspectionId} />
                <PresentationOverview
                  presentations={orgPresentations}
                  inspectionId={inspectionId}
                  dynamicPresentation={jobPresentation}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </SpinnerComponent>
    );
  }

  public goToPresentation = () => {
    const { job } = this.props;
    const $state = StoreRegistry.get("$state");

    $state.go("presentationviewer", { id: job.id });
  };

  public savePresentation = () => {
    const { job, saveDynamicPresentation, jobPresentation, orgPresentations } = this.props;

    saveDynamicPresentation(job.id, jobPresentation, orgPresentations).then(() => {
      this.setState({ isNew: false });
      this.resetState();
    });
  };

  public applyTemplate(template: PresentationTemplateRecord) {
    const { jobPresentation, orgPresentations, updatePresentation } = this.props;

    updatePresentation(builderService.applyTemplateToPresentation(template, jobPresentation, orgPresentations));
  }

  public check = (): boolean => {
    const { savedState } = this.state;
    const { jobPresentation } = this.props;

    if (!jobPresentation || !savedState) {
      return false;
    }

    const newCheck = savedState !== JSON.stringify(jobPresentation.toJS());

    this.setState((prevState) => {
      if (prevState.dirty !== newCheck) {
        return {
          dirty: newCheck,
        };
      }
    });
    return newCheck;
  };

  // return the state to the initial, reload from server
  public reset = (): ng.IPromise<any> => {
    const { loadJobPresentation, orgPresentations, job } = this.props;
    const { inspectionId } = this.state;

    return (loadJobPresentation(job.id, orgPresentations, inspectionId).then((j) => {
      this.setState({
        savedState: JSON.stringify(this.props.jobPresentation.toJS()),
        dirty: false,
        isNew: j.id <= 0,
      });
    }) as any) as ng.IPromise<any>;
  };

  // An Initial State setting
  public resetState = () => {
    const { jobPresentation } = this.props;

    this.setState({
      savedState: JSON.stringify(jobPresentation?.toJS()),
      dirty: false,
    });
  };

  public trackEvent = (action: any, props: any): void => {
    const { tracking, jobPresentation } = this.props;

    tracking.trackEvent({
      action: action,
      presentation: jobPresentation ? jobPresentation.id : null,
      ...props,
    });
  };

  public checkInspectionSlide = () => {
    const { updatePresentation } = this.props;
    let { jobPresentation } = this.props;
    const { inspectionId } = this.state;

    jobPresentation = builderService.checkInspectionDynamicPresentationLink(jobPresentation, inspectionId);

    updatePresentation(jobPresentation);
    return jobPresentation;
  };
}

export default connector(PresentationBuilderContainer);
