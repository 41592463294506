import { IOrgResource, IOrg } from "@rsfApp/app/src/Models/Org";
import { IAccount, IAccountResource, IInvoiceTextLine } from "@rsfApp/app/src/Billing/Models/Account";
import * as angulartics from "angulartics";
import { IPretty } from "app/src/Common/PrettyNameService";
import { IBaseConfig } from "@rsfApp/app/src/Common/IBaseConfig";

interface IEnterStateParams extends ng.ui.IStateParamsService {
  id: number;
}

export class EnterCtrl {
  public account: IAccount;
  public org: IOrg;
  public submission: ng.IPromise<any>;
  public handler: ng.stripe.IStripeCheckoutHandler;
  public successfullyEntered = false;
  public dynamicLines = false;
  public lines: IInvoiceTextLine[];
  public cbMessage = "Redirecting...";

  public static $inject = [
    "Org",
    "Account",
    "$stateParams",
    "$state",
    "$sce",
    "StripeCheckout",
    "BaseConfig",
    "$analytics",
    "Pretty",
  ];
  constructor(
    public OrgResource: IOrgResource,
    public AccountResource: IAccountResource,
    public $stateParams: IEnterStateParams,
    public $state: ng.ui.IStateService,
    public $sce: ng.ISCEService,
    public StripeCheckout: ng.stripe.IStripeCheckoutService,
    public BaseConfig: IBaseConfig,
    private $analytics: angulartics.IAnalyticsService,
    public Pretty: IPretty
  ) {
    if (!$stateParams.id) {
      $state.go("root.login");
    } else {
      StripeCheckout.load().then(() => {
        this.handler = StripeCheckout.configure(<ng.stripe.IStripeCheckoutConfigureOptions>{
          name: this.BaseConfig.STRIPE_NAME,
          allowRememberMe: false,
          image: this.BaseConfig.STRIPE_IMAGE,
        });
      });

      this.account = AccountResource.landing({
        key: $stateParams.id,
        "include[]": ["plan", "fee", "customers", "invoice_text"],
      });

      this.account.$promise.then(() => {
        if (this.account.customers.length > 0 && this.account.customers[0].billing_type === "chargebee") {
          const customer = this.account.customers[0];
          if (customer.metadata?.hosted_page?.url) {
            window.location = customer.metadata.hosted_page.url;
          } else {
            this.cbMessage = `There is a problem with this quote.  Please contact support for futher assistance at ${this.BaseConfig.CONTACT_PHONE}.`;
          }
        } else if (
          this.account.invoice_text &&
          _.isArray(this.account.invoice_text.lines) &&
          this.account.invoice_text.lines.length > 0
        ) {
          this.dynamicLines = false;
          this.lines = this.account.invoice_text.lines;

          _.each(this.lines, (line) => {
            if (line.header === "") {
              line.header = "&nbsp;"; // this is needed else spacing isn't even
            }

            line.header = this.$sce.trustAsHtml(line.header);
            line.text = this.$sce.trustAsHtml(line.text);
          });
        } else {
          this.dynamicLines = true;
        }
        this.org = this.OrgResource.byUuid({ uuid: this.account.org_id.toString(36) });
        return this.org.$promise.then(() => {
          this.$analytics.eventTrack("payment loaded", { category: "Billing", value: this.org.id });
        });
      });
    }
  }

  public open() {
    this.$analytics.eventTrack("load stripe", { category: "Billing", value: this.org.id });
    this.handler
      .open({
        description: this.account.plan.name,
      })
      .then((result) => {
        this.$analytics.eventTrack("stripe submitted", { category: "Billing", value: this.org.id });
        this.submission = this.AccountResource.setup({
          key: this.$stateParams.id,
          email: result[0].email,
          stripe_id: result[0].id,
        }).$promise;

        this.submission.then(() => {
          this.successfullyEntered = true;
        });
      });
  }

  public dueToday() {
    let total = 0;
    if (this.account.fee) {
      total = this.account.fee.amount;
    }

    if (this.account.plan && this.account.plan.trial_period_days === 0) {
      total += this.account.plan.amount * this.account.user_count;
    }

    return total;
  }

  public moduleNames() {
    if (!this.org || !this.org.settings) {
      return this.Pretty.name["estimate_module"];
    }

    let title = "";

    if (_.includes(this.org.settings.acl["modules"], "estimate_module")) {
      title = this.Pretty.name["estimate_module"];
    }

    if (_.includes(this.org.settings.acl["modules"], "leads_module")) {
      if (title !== "") {
        title = `${title} and ${this.Pretty.name["leads_module"]}`;
      } else {
        title = this.Pretty.name["leads_module"];
      }
    }

    if (title === "") {
      title = this.Pretty.name["estimate_module"];
    }

    return title;
  }

  public supportEmail() {
    if (!this.org || !this.org.settings) {
      return this.BaseConfig.ESTIMATE_MODULE_CONTACT_EMAIL;
    }

    if (_.includes(this.org.settings.acl["modules"], "leads_module") && this.org.settings.acl["modules"].length === 1) {
      return this.BaseConfig.LEADS_MODULE_CONTACT_EMAIL;
    }

    return this.BaseConfig.ESTIMATE_MODULE_CONTACT_EMAIL;
  }
}
