import * as React from "react";
import { ThunkDispatch } from "redux-thunk";
import { connect, ConnectedProps } from "@rsfApp/app2/src/connect";
import { RootState, RootActions } from "app2/src/reducers";
import { Container, Row, Col } from "react-bootstrap";
import { UserRecord, getFullName } from "@rsfApp/app2/src/records/UserRecord";

const mapStateToProps = (state: RootState, ownProps: ICustomerStatsProps) => {
  return {
    currentUser: state.getIn(["users", "currentUser"]),
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<RootState, {}, RootActions>, ownProps: ICustomerStatsProps) => {
  return {};
};

const connector = connect(mapStateToProps, mapDispatchToProps);

interface ICustomerStatsProps {
  stats: any;
}

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & ICustomerStatsProps;

export interface ICustomerStatsState {}

class CustomerStats extends React.Component<Props, ICustomerStatsState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  public render() {
    const { currentUser, stats } = this.props;
    return (
      <Row>
        <Col sm={12}>
          <div className="form-section customer-stats">
            <h5>Total Customers YTD</h5>
            <h2>{stats.total}</h2>
            <p>New: {stats.new}</p>
            <p>Scheduled: {stats.scheduled}</p>
            <p>Rescheduled: {stats.scheduled_second}</p>
            <p>Followup: {stats.followup}</p>
            <p>Closed Sold: {stats.closed_sold}</p>
            <p>Lost: {stats.lost}</p>
            <div className="pie" style={{ height: 500 }}></div>
          </div>
        </Col>
      </Row>
    );
  }
}

export default connector(CustomerStats);
